import { Popover, Transition } from "@headlessui/react";
import { PaintBrushIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import appStore from "store/AppStore";
import uiUseStore from "store/UIStore";
import { Tenant } from "types/tenant";
import {
  buildCareTenantDetails,
  buildDefaultTenantDetails,
  buildSpotTenantDetails,
  buildTenantDetails,
  buildZocdocTenantDetails,
} from "utils/tenant";

export default function ThemeDropdown() {
  const { setTenant } = appStore();
  const { isHomePage } = uiUseStore();

  return (
    <div className="AvatarDropdown">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`text-2xl md:text-3xl relative w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center`}
            >
              <PaintBrushIcon className="w-6 h-6" aria-hidden="true" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                  <div
                      className="cursor-pointer flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      onClick={() => {
                        setTenant(Tenant.Spot);
                        close();
                      }}
                    >
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                        <img
                          src={buildSpotTenantDetails().logo.symbol}
                          aria-hidden="true"
                          className="w-6 h-6"
                          alt="Load again"
                        />
                      </div>
                      <span className="ml-4">
                        <p className="text-sm font-medium ">Spot care</p>
                      </span>
                    </div>
                    <div
                      className="cursor-pointer flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      onClick={() => {
                        setTenant(Tenant.DRD);
                        close();
                      }}
                    >
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                        <img
                          src={buildDefaultTenantDetails().logo.symbol}
                          aria-hidden="true"
                          className="w-6 h-6"
                          alt="Load again"
                        />
                      </div>
                      <span className="ml-4">
                        <p className="text-sm font-medium ">Post Acute</p>
                      </span>
                    </div>
                    <div
                      className="cursor-pointer flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      onClick={() => {
                        setTenant(Tenant.WELLSTAR);
                        close();
                      }}
                    >
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                        <img
                          src={buildTenantDetails().logo.symbol}
                          aria-hidden="true"
                          className="w-6 h-6"
                          alt="Load again"
                        />
                      </div>
                      <span className="ml-4">
                        <p className="text-sm font-medium ">WellStar</p>
                      </span>
                    </div>
                    <div
                      className="cursor-pointer flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      onClick={() => {
                        setTenant(Tenant.Zocdoc);
                        close();
                      }}
                    >
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                        <img
                          src={buildZocdocTenantDetails().logo.symbol}
                          aria-hidden="true"
                          className="w-6 h-6"
                          alt="Load again"
                        />
                      </div>
                      <span className="ml-4">
                        <p className="text-sm font-medium ">Zocdoc</p>
                      </span>
                    </div>
                    <div
                      className="cursor-pointer flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      onClick={() => {
                        setTenant(Tenant.Care);
                        close();
                      }}
                    >
                      <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                        <img
                          src={buildCareTenantDetails().logo.symbol}
                          aria-hidden="true"
                          className="w-6 h-6"
                          alt="Load again"
                        />
                      </div>
                      <span className="ml-4">
                        <p className="text-sm font-medium ">Hayath</p>
                      </span>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

import React from "react";
import { ReactNode } from "react";

export interface Heading2Props {
    heading?: ReactNode;
    subHeading?: ReactNode;
    className?: string;
}

const CustomHeading: React.FC<Heading2Props> = ({
    className = "",
    heading = "Providers in New York",
    subHeading = "",
}) => {
    return (
        <div className={`mb-12 lg:mb-16 ${className}`}>
            <h2 className="text-4xl font-semibold">{heading}</h2>
            {subHeading && (
                <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
                    {subHeading}
                </span>
            )}
        </div>
    );
};

export default CustomHeading;

export const Config = {
    URL: {
        API_URL: "https://pacrn-backend-dev-hfbue2d6dnafavgp.eastus-01.azurewebsites.net/api/",
        "API_V2": "https://pacrn-backend-dev-hfbue2d6dnafavgp.eastus-01.azurewebsites.net/",
        PROXY: 'https://cors-anywhere.herokuapp.com/'
    },
    KEY: {
        MAP: "AIzaSyDMosO-Ce1ra3DFbiwOF6peKy0M9vtVIEc",
        LOCALDB: "carelike",
        DBSTORE: "pellucid",
        ANALYTICSTRACKINGID: "GTM-WL5ZZ6JF"
    }
}
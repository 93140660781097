import { useCallback } from 'react';
import Cookies from 'js-cookie';

type UseCookieReturnType = {
  setCookie: (name: string, value: string, days: number) => void;
  getCookie: (name: string) => string | undefined;
  removeCookie: (name: string) => void;
};

const useCookie = (): UseCookieReturnType => {
  const setCookie = useCallback((name: string, value: string, days: number) => {
    Cookies.set(name, value, { expires: days });
  }, []);

  const getCookie = useCallback((name: string): string | undefined => {
    return Cookies.get(name);
  }, []);

  const removeCookie = useCallback((name: string) => {
    Cookies.remove(name);
  }, []);

  return { setCookie, getCookie, removeCookie };
};

export default useCookie;

import React, { useState, useEffect, useRef } from "react";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import {
  EnvelopeIcon,
  DocumentTextIcon,
  ArrowDownTrayIcon,
  TrashIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import uiUseStore from "store/UIStore";
import appStore from "store/AppStore";
import { ProviderCard } from "containers/PageList/ProviderCard";
import ReportTemplate from "shared/PDFTemplate/ReportTemplate";
import { usePDF, BlobProvider } from "@react-pdf/renderer";
import { MarkerPin } from "types/MarkerPin";
import {
  TransformListToCSV,
  downloadCSV,
  epicCsvBuilder
} from "utils/csvConvertor";
import { CSVExport } from "types/CSVExport";
import { json2csv } from "json-2-csv";
import { Providers } from "types/ProviderDetails";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Services } from "services/service";
import SuccessDialog from "./SuccessDialog";

export interface NavMobileProps {
  data?: NavItemType[];
  className?: string;
}

interface IconButtonProps {
  Icon: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & React.RefAttributes<SVGSVGElement>
  >;
  onClick: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION_DEMO,
  className = ""
}) => {
  const { setEmailDialogOpen, setIsDrawerClose, CareType, EmailDialogOpen, isEmailSendClicked,
    setIsEmailSendClicked } = uiUseStore();
  const { savedProviderList, setSavedProviderList, tenantConfig, setLoader } = appStore();
  const [currentDateTime, setCurrentDateTime] = useState<string>("");
  const [saveProfileDetails, setSaveProfileDetails] = useState<Providers[]>([]);
  const [triggerDownload, setTriggerDownload] = useState<boolean>(false);
  const [csvList, setCsvList] = useState<CSVExport[]>([]);
  const [email, setEmail] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const emailInputRef = useRef<HTMLInputElement>(null);
  const emailSendRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setCsvList(TransformListToCSV(savedProviderList ?? []));
  }, [savedProviderList]);

  const IconButton: React.FC<IconButtonProps> = ({ Icon, onClick }) => {
    return (
      <button
        className={`text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none flex items-center justify-center ${className}`}
        onClick={onClick}
      >
        <Icon className="w-5 h-5 cursor-pointer" aria-hidden="true" />
      </button>
    );
  };
  const handleClick = () => {
    epicCsvBuilder(csvList);
  };
  const openEmailDialog = () => {
    setEmailDialogOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerClose(true);
  };

  const clearAllSavedProviderList = () => {
    setSavedProviderList([]);
    setIsDrawerClose(true);
  };

  useEffect(() => {
    if (triggerDownload) {
      setSaveProfileDetails(savedProviderList || []);
    }
  }, [triggerDownload, savedProviderList]);

  const handleDownloadClick = () => {
    console.log(saveProfileDetails);
    const now = new Date()
      .toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      })
      .replace(",", "");
    setCurrentDateTime(now);
    setTriggerDownload(true);
  };

  const sendEmail = async (blob: Blob) => {
    await Services.SendEmail(blob, email);
    setLoader(false);
    setIsEmailSendClicked(false);
    setEmailDialogOpen(false);
    setIsSuccessDialogOpen(true);
  };

  const onEmailSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (emailInputRef.current?.checkValidity()) {
      setIsEmailSendClicked(true);
      setEmail(emailInputRef.current.value);
    }
  };

  const handleClose = () => {
    setEmailDialogOpen(false);
  };

  return (
    <div className="w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-800 divide-y-2 divide-neutral-100 dark:divide-neutral-800 drawer-container">
      <div className="flex flex-col border-b dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 text-sm px-3 ">
        <div className="flex justify-between items-center py-1 px-4">
          <p className="w-fit text-xl font-medium">
            Wishlist{" "}
            <span className="light:text-primary-500 dark:text-white-500">
              ({savedProviderList?.length})
            </span>
          </p>
          <div className="flex justify-end items-center">
            <IconButton Icon={EnvelopeIcon} onClick={openEmailDialog} />
            <IconButton Icon={DocumentTextIcon} onClick={handleClick} />
            <IconButton
              Icon={ArrowDownTrayIcon}
              onClick={handleDownloadClick}
            />
            {triggerDownload && (
              <BlobProvider
                document={
                  <ReportTemplate
                    currentDateTime={currentDateTime}
                    savedProviderDetails={saveProfileDetails}
                    logo={tenantConfig.logo.full}
                    caretype={CareType ?? ""}
                  />
                }
              >
                {({ blob, url, loading, error }) => {
                  if (loading) return "Loading...";
                  if (error) return "Error loading document";
                  if (blob) {
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = "providers.pdf";
                    link.click();
                    setTriggerDownload(false);
                  }
                  return null;
                }}
              </BlobProvider>
            )}
            {/* <IconButton Icon={TrashIcon} onClick={clearAllSavedProviderList} /> */}
            <IconButton Icon={XMarkIcon} onClick={handleDrawerClose} />
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full overflow-y-auto scrollbar-hide gap-y-2 px-4 pt-4 pb-28">

        {
          EmailDialogOpen &&
          <div className="flex flex-col text-neutral-700 dark:text-neutral-300 justify-center items-start gap-y-6 px-6 pt-6 pb-5">
            <p className="text-base font-normal text-center">
              You can send the saved providers to recipients. Please enter the email address
            </p>
            <form
              className="w-full flex flex-col justify-center items-center gap-y-6"
              ref={emailSendRef}
              onSubmit={onEmailSubmit}
            >
              <div className="w-full">
                <span className="font-semibold text-neutral-800 dark:text-neutral-200">
                  Email address
                </span>
                <Input
                  type="email"
                  required
                  aria-required="true"
                  placeholder="example@gmail.com"
                  className={`mt-2`}
                  rounded="rounded-lg "
                  ref={emailInputRef}
                  onInvalid={(e) => {
                    const target = e.target as HTMLInputElement;
                    if (!target.value) {
                      target.setCustomValidity(
                        "Please fill out the email address"
                      );
                    } else {
                      target.setCustomValidity(
                        "Please include a valid email address"
                      );
                    }
                  }}
                  onInput={(e) => {
                    (e.target as HTMLInputElement).setCustomValidity("");
                  }}
                />
              </div>
              <div className="w-full flex flex-row justify-between items-center">
                <ButtonSecondary  onClick={handleClose}>
                  Cancel
                </ButtonSecondary>

                {isEmailSendClicked ? (
                  <BlobProvider
                    document={
                      <ReportTemplate
                        currentDateTime={new Date()
                          .toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit"
                          })
                          .replace(",", "")}
                        savedProviderDetails={saveProfileDetails}
                        logo={tenantConfig.logo.full}
                        caretype={CareType ?? ""}
                      />
                    }
                  >
                    {({ blob, url, loading, error }) => {
                      if (loading) return "sending...";
                      if (error) return "Error loading document";
                      if (blob) {
                        if (isEmailSendClicked) {
                          debugger
                          sendEmail(blob);
                        }
                      }
                      return null;
                    }}
                  </BlobProvider>
                ) : (
                  <ButtonPrimary type="submit" className="rounded p-1">
                    Send
                  </ButtonPrimary>
                )}
              </div>
            </form>
          </div>
        }

        {
          isSuccessDialogOpen &&
          <SuccessDialog setIsSuccessDialogOpen={setIsSuccessDialogOpen} />
        }

        {savedProviderList?.map((item) => (
          <div key={item.code}>
            <ProviderCard data={item} fromDrawer={true} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavMobile;

import { CSVExport } from "types/CSVExport";
import { MarkerPin } from "types/MarkerPin";
import { getRandomProviderNumber } from "./converter";
import { json2csv } from "json-2-csv";
import { Providers } from "types/ProviderDetails";

export const TransformListToCSV = (savedProviders: Providers[]) => {
    let CSVExport: CSVExport[] = [];
    savedProviders.map((value) => {
        let exporter: CSVExport = {
            id: getRandomProviderNumber(),
            providerName: value.name ?? "",
            providerId: parseInt(value.code) ?? 0,
            address: value.locations[0].address ?? "",
            city: value.locations[0].city ?? "",
            state: value.locations[0].state ?? "",
            locationId: 1,
            zip: value.locations[0].postalCode ?? "",
            phone: value.phone ?? "",
            fax: "",
            vendor: "Carelike"
        }
        CSVExport.push(exporter);
    })

    return CSVExport;
}

export function epicCsvBuilder(csvList: CSVExport[]): void {
    const csvStringList = json2csv(csvList).split('\n');  
    csvStringList.shift();
    const csvHeaders: string[] = [
      "##INI=EAF,,,,,,,,,,,,,,,,,,,,,,,,,,",
      "##vendorIIT=840,,,,,,,,,,,,,,,,,,,,,,,,,,",
      ",,,,,,,Zip,Phone,Fax,,,Service * Type of service,,,,,,,,,,,,,,",
      "1,2,27,400,410,420,426,430,450,490,8925,5005,77035,77035,77035,77035,77035,77035,77035,77035,77035,77035,77035,77035,77035,77035,77035"
    ] 
    downloadCSV((csvHeaders.concat(csvStringList)).join("\n"), "provider-epic.csv");
}

export function downloadCSV(data: string, filename: string = 'data.csv'): void {
    // Create a Blob from the data
    const blob: Blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });

    // Create a link element
    const link: HTMLAnchorElement = document.createElement('a');

    // Use the URL.createObjectURL to create a temporary URL for the blob
    const url: string = URL.createObjectURL(blob);
    link.href = url;
    link.download = filename;

    // Append link to the body, trigger click, and then remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    // Optionally release the object URL
    window.URL.revokeObjectURL(url);
}


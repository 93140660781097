import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Config } from "constants/config";
import appStore from "store/AppStore";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import { darkMap } from "./dark";
import { MarkerPin } from "types/MarkerPin";
import { Providers } from "types/ProviderDetails";
import { lightMap } from "./light";

interface GoogleMapProps {
  selectedId: string | number;
  latLng?: { lat: number; lng: number };
}

export const GoogleMap: React.FC<GoogleMapProps> = ({ selectedId, latLng }) => {
  const { filteredPaginatedList, theme } = appStore();

  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (map) {
      map.setOptions({ styles: theme === "dark" ? darkMap : lightMap });
    }
  }, [theme]);

  // useEffect(() => {
  //   if (map) {
  //     if (latLng?.lat && latLng?.lng) {
  //       map.panTo({ lat: latLng?.lat || 0, lng: latLng?.lng || 0 });
  //     }
  //   }
  // }, [latLng, selectedId]);

  let markerProps = {
    center: {
      lat: latLng?.lat || 0,
      lng: latLng?.lng || 0
    },
    zoom: 9.5
  };

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      filteredPaginatedList.forEach((coord: Providers) => {
        bounds.extend(
          new google.maps.LatLng(
            coord.locations[0].latitude,
            coord.locations[0].longitude
          )
        );
      });
      map.fitBounds(bounds);
      // map.setZoom(markerProps.zoom);
      map.setOptions({
        draggable: true,
        zoomControl: true,
        scrollwheel: true,
        disableDoubleClickZoom: false
      });
      if (theme) map.setOptions({ styles: theme === "dark" ? darkMap : lightMap });
    }
  }, [filteredPaginatedList, map]);

  return (
    <GoogleMapReact
      defaultZoom={9.5}
      defaultCenter={{ lat: 59.95, lng: 30.33 }}
      bootstrapURLKeys={{
        key: Config.KEY.MAP
      }}
      yesIWantToUseGoogleMapApiInternals
      options={{
        mapTypeControl: false,
        streetViewControl: true,
        fullscreenControl: false
      }}
      onGoogleApiLoaded={({ map }) => setMap(map)}
    >
      {filteredPaginatedList.map((item) => (
        <AnyReactComponent
          isSelected={selectedId === item.code}
          lat={item.locations[0].latitude}
          lng={item.locations[0].longitude}
          key={item.code}
          item={item}
        />
      ))}
    </GoogleMapReact>
  );
};

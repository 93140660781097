import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import { Config } from "constants/config";
import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import appStore from "store/AppStore";
import { MarkerPin } from "types/MarkerPin";
import { darkMap } from "./dark";
import { lightMap } from "./light";
import { Providers } from "types/ProviderDetails";

interface ContainerMapProps {
  markers: Providers;
}

export const ContainerMap: React.FC<ContainerMapProps> = ({ markers }) => {
  const [map, setMap] = useState<google.maps.Map>();
  const { theme } = appStore();

  useEffect(() => {
    if (map) {
        map.setOptions({ styles: theme === "dark" ? darkMap : lightMap });
    }
}, [theme])

  useEffect(() => {
    if (map) {
      map.setOptions({ styles: theme === "dark" ? darkMap : lightMap });
    }
  }, [map]);

  return (
    <GoogleMapReact
      defaultZoom={14}
      defaultCenter={{
        lat: markers.locations[0].latitude,
        lng: markers.locations[0].longitude
      }}
      bootstrapURLKeys={{
        key: Config.KEY.MAP
      }}
      yesIWantToUseGoogleMapApiInternals
      options={{
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: true
      }}
      onGoogleApiLoaded={({ map }) => setMap(map)}
    >
      <AnyReactComponent
        lat={markers.locations[0].latitude}
        lng={markers.locations[0].longitude}
        key={markers.code}
        item={markers}
        isList={false}
      />
    </GoogleMapReact>
  );
};

import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import { Page } from "./types";
import Page404 from "containers/Page404/Page404";
import SiteHeader from "containers/SiteHeader";
import PageHome from "containers/PageHome/PageHome";
import PageList from "containers/PageList/PageList";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import uiUseStore from "store/UIStore";
import CareTypeInput from "components/HeroSearchForm/CareTypeInput";
import LocationInput from "components/HeroSearchForm/LocationInput";
import appStore from "store/AppStore";
import RadiusInput from "components/HeroSearchForm/RadiusInput";
import { Services } from "services/service";
import { parseProviderResults } from "contains/makers";
import { APP_CONSTANTS } from "constants/AppConstants";
import toast from "react-hot-toast";
import Logo from "shared/Logo/Logo";
import { useTenantConfigs as UseTenantConfigs } from "utils/tenant";
import ScrollToTop from "./ScrollToTop";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSignup from "containers/PageSignUp/PageSignUp";
import CustomDialog from "components/Dialog/CustomDialog";
import PageProfile from "containers/PageProfile/PageProfile";
import PageOTP from "containers/PageOTP/PageOTP";
import { KEYS } from "constants/KeyConstants";
import { isValidToken } from "utils/tokenValidators";
import ReportIssueDialog from "containers/dialog/ReportIssueDialog";
import SuccessDialog from "shared/SuccessDialog/SuccessDialog";
import PageContact from "containers/PageContact/PageContact";
import { logPageView } from "utils/analytics";
import AccountPage from "containers/AccountPage/AccountPage";

const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname);
  }, [location]);

  return null;
};

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/list", exact: true, component: PageList },
  { path: "/login", exact: true, component: PageLogin },
  { path: "/signup", exact: true, component: PageSignup },
  { path: "/account", exact: true, component: AccountPage },
  { path: "/verify", exact: true, component: PageOTP },
  { path: "/contact", exact: true, component: PageContact }
];

const PACRNRoutes = () => {
  const [navigateToList, setNavigateToList] = useState(false);
  const {
    setLoader,
    setProvidersList,
    setOriginalList,
    setFilteredPaginatedList,
    setSavedProviderList,
    tenant,
    setTenantConfigs,
    theme
  } = appStore();

  //const WIN_WIDTH = useWindowSize().width || window.innerWidth;
  const {
    showHeroMobileSearch,
    setShowHeroMobileSearch,
    setCareTypeMobile,
    setLocationTypeMobile,
    setRadiusTypeMobile,
    CareType,
    storeLongitute,
    storeLatitude,
    storePostalCode,
    HeaderRadius,
    setServicesTag,
    setIsHomePage,
    setIsForceLogin,
    setIsLoggedIn,
    showLogin,
    showSignup,
    isReportDialogOpen,
    setIsReportDialogOpen,
    isReportSuccessDialogOpen,
    setIsReportSuccessDialogOpen
  } = uiUseStore();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [logo, setLogo] = useState<string | undefined>();

  useEffect(() => {
    const tenantDetails = UseTenantConfigs(tenant);
    setLogo(tenantDetails.logo.symbol);
    validateAuthentication();
  }, []);

  useEffect(() => {
    const tenantDetails = UseTenantConfigs(tenant);
    setLogo(tenantDetails.logo.symbol);
    setTenantConfigs(tenantDetails);
  }, [tenant, theme]);

  useEffect(() => {
    if (selectedIndex === 0) {
      setCareTypeMobile(true);
    }
    if (selectedIndex === 1) {
      setLocationTypeMobile(true);
    }
    if (selectedIndex === 2) {
      // This should be 2 for RadiusInput, not 1
      setRadiusTypeMobile(true);
    }
  }, [selectedIndex, showHeroMobileSearch]);

  const validateAuthentication = () => {
    if (!isValidToken()) {
      localStorage.removeItem(KEYS.TOKEN);
      localStorage.removeItem(KEYS.ISLOGGEDIN);
    }
  };

  const onSubmit = async () => {
    try {
      const filterData = {
        careType: CareType ?? "",
        lat: storeLatitude ?? 0.0,
        lon: storeLongitute ?? 0.0,
        page: 1,
        pageSize: 10,
        postalCode: storePostalCode ?? "",
        radius: HeaderRadius ?? ""
      };
      if (
        !filterData.careType ||
        !filterData.radius ||
        !filterData.postalCode
      ) {
        toast.error("Enter Required Field to Search", {
          position: "top-center"
        });
      } else {
        setShowHeroMobileSearch(false);
        setLoader(true);
        let { data } = await Services.LoadCaresAgainstFilters(filterData);
        let result = await parseProviderResults(data, filterData);
        setProvidersList(result);
        setOriginalList(result);
        setFilteredPaginatedList(
          result.slice(0, APP_CONSTANTS.PAGINATION_LIMIT)
        );
        setNavigateToList(true);
      }
    } finally {
      setLoader(false);
      setSavedProviderList([]);
      setServicesTag(CareType);
    }
  };

  const heroSerarchForm2Mobile = () => {
    return (
      <div className="HeroSearchForm2Mobile xs:block md:hidden">
        <Transition appear show={showHeroMobileSearch} as={Fragment}>
          <Dialog
            as="div"
            className="HeroSearchFormMobile__Dialog relative z-max xs:block md:hidden"
            onClose={closeDialog}
          >
            <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
              <div className="flex h-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out transition-transform"
                  enterFrom="opacity-0 translate-y-52"
                  enterTo="opacity-100 translate-y-0"
                  leave="ease-in transition-transform"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-52"
                >
                  <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between xs:block md:hidden">
                    <Tab.Group
                      selectedIndex={selectedIndex}
                      onChange={setSelectedIndex}
                      manual
                    >
                      <div className={`relative z-10 flex flex-1`}>
                        <Logo
                          img={logo}
                          imgLight={logo}
                          className={
                            "p-5 pl-10 transition-opacity duration-700 ease-in"
                          }
                        />
                      </div>
                      <div className="absolute right-8 top-6 z-50">
                        <button onClick={closeDialog}>
                          <XMarkIcon className="w-8 h-8 text-black dark:text-white" />
                        </button>
                      </div>
                      <div className="absolute bottom-4 px-5 w-full flex justify-between items-center">
                        {/* <button
                          type="submit"
                          className="underline font-semibold flex-shrink-0"
                          style={{ zIndex: "99999999" }}
                          onClick={clearFilter}
                        >
                          Clear all
                        </button> */}
                        <button
                          type="submit"
                          onClick={onSubmit}
                          className={`flex-shrink-0 px-2 py-1.5 w-full cursor-pointer rounded-xl bg-primary-6000 flex items-center justify-center text-neutral-50 focus:outline-none relative z-20`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={1.5}
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                          </svg>
                          <span className="ml-2">Search</span>
                        </button>
                      </div>
                      <div className="h-full w-full flex flex-col items-center justify-start py-14 gap-y-14">
                        <div className="transition-opacity animate-[myblur_0.4s_ease-in-out] w-full px-10">
                          <CareTypeInput
                            className="flex-1"
                            mobileClassName={"nc-hero-field-focused"}
                          />
                        </div>
                        <div className="transition-opacity animate-[myblur_0.4s_ease-in-out] w-full">
                          <LocationInput
                            className="flex-1 "
                            mobileClassName={"nc-hero-field-focused"}
                          />
                        </div>
                        <div className="transition-opacity animate-[myblur_0.4s_ease-in-out] w-full px-10">
                          <RadiusInput
                            className={`flex-1`}
                            mobileClassName={"nc-hero-field-focused"}
                          />
                        </div>
                      </div>
                    </Tab.Group>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  const closeDialog = () => {
    setShowHeroMobileSearch(false);
    setCareTypeMobile(false);
    setLocationTypeMobile(false);
  };

  const CheckIsAlreadyLoggedIn = () => {
    let result = localStorage.getItem(KEYS.ISLOGGEDIN) === "true";
    if (result) setIsLoggedIn(true);
  };

  useEffect(() => {
    setIsHomePage(true);
    CheckIsAlreadyLoggedIn();
  }, []);

  const onCloseForceLogin = () => {
    setIsForceLogin(false);
  };

  const handleReportDialogClose = () => {
    setIsReportDialogOpen(false);
  };

  const handleReportSuccessDialogClose = () => {
    setIsReportSuccessDialogOpen(false);
  };

  return (
    <BrowserRouter>
      <RouteTracker />
      <ScrollToTop />
      <SiteHeader />
      <div className="flex-1 ">
        <Routes>
          {pages.map(({ component, path }) => {
            const Component = component;
            return <Route key={path} element={<Component />} path={path} />;
          })}
          <Route path="*" element={<Page404 />} />
        </Routes>
        {navigateToList && <Navigate to="/list" replace />}
      </div>
      {heroSerarchForm2Mobile()}

      <CustomDialog
        isDialogOpen={showSignup}
        handleClose={onCloseForceLogin}
        children={<PageSignup />}
      />

      <CustomDialog
        isDialogOpen={showLogin}
        handleClose={onCloseForceLogin}
        children={<PageLogin />}
      />
      <CustomDialog
        isDialogOpen={isReportDialogOpen}
        handleClose={handleReportDialogClose}
        children={<ReportIssueDialog />}
      />
      <CustomDialog
        isDialogOpen={isReportSuccessDialogOpen}
        handleClose={handleReportSuccessDialogClose}
        children={
          <SuccessDialog
            img={`${process.env.PUBLIC_URL}/success.png`}
            content="Thank you for sharing your concern. Our safety team will review and take appropriate action."
            onClick={handleReportSuccessDialogClose}
            btnString="Close"
          />
        }
      />
    </BrowserRouter>
  );
};

export default PACRNRoutes;

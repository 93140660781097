import React, { FC, useEffect, useRef, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import uiUseStore from "store/UIStore";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import appStore from "store/AppStore";
import { Services } from "services/service";
import OTPInput from "./OTPInput";
import toast from "react-hot-toast";
import useCookie from "utils/useCookies";
import { KEYS } from "constants/KeyConstants";
import lottie from "lottie-web";
import login from "../../images/Lottie/login.json";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import care from "images/banners/care3.png";
import logo from "images/app/spot/symbol.png";

export interface PageSignupProps {
  className?: string;
  callback?: () => void;
}

interface FormData {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  phone: yup
    .string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .required("Phone is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required")
});

const PageSignup: FC<PageSignupProps> = ({ className = "", callback }) => {
  const { setIsLoginPage, setIsForceLogin, setShowLogin, setShowSignup } = uiUseStore();
  const { loading, setLoader } = appStore();
  const [isVerificationStarted, setisVerificationStarted] = useState(false);
  const [email, setEmail] = useState("");
  const { setCookie, removeCookie } = useCookie();
  const signUpRef = useRef(null);

  useEffect(() => {
    setIsLoginPage(true);
    if (signUpRef.current) {
      const lottieInstance = lottie.loadAnimation({
        container: signUpRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: login
      });

      return () => {
        lottieInstance.destroy();
      };
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setLoader(true);
      await Services.Register(
        data.firstName,
        data.lastName,
        data.phone,
        data.email,
        data.password
      );
      setEmail(data.email);
      setCookie(KEYS.EMAIL, data.email, 20);
      setCookie(KEYS.VERIFICATIONPENDING, "true", 20);
      toast.success("Verification code sent to your email");
      setisVerificationStarted(true);
    } catch (error) {
      toast.error("Error in registration");
    } finally {
      setLoader(false);
    }
  };

  const onVerificationSubmit = async (otp: string) => {
    try {
      setLoader(true);
      let result = await Services.Verify(otp, email);
      console.log(result);
      setisVerificationStarted(false);
      removeCookie(KEYS.EMAIL);
      removeCookie(KEYS.VERIFICATIONPENDING);
      // if (callback) callback();
      setShowSignup(false);
      setShowLogin(true);
    } catch (error) {
      toast.error("OTP is wrong");
    } finally {
      setLoader(false);
    }
  };

  const onCreateAccountClick = () => {
    setShowLogin(true);
    setShowSignup(false);

  }


  // return !isVerificationStarted ? <div className={`nc-PageLogin relative w-full rounded-xl flex  ${className}`} data-nc-id="PageLogin">
  //   <div className="hidden lg:block xl:block flex-1 p-4" ref={signUpRef}>
  //   </div>
  //   <div className="flex-1 justify-center items-center w-full space-y-4 xs:mx-10 mx-20">
  //     <h2 className="text-3xl font-semibold text-neutral-900 dark:text-neutral-100 text-center">Register</h2>
  //     <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-8 space-y-3 text-left">

  //       <div className="flex justify-between">
  //         <div className="space-y-1">
  //           <label className="block text-neutral-800 dark:text-neutral-200 text-xs ml-4">First Name</label>
  //           <input
  //             {...register("firstName")}
  //             type="text"
  //             placeholder="First Name"
  //             className={`block w-full h-11 px-4 rounded-xl border ${errors.firstName ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
  //           />
  //           {errors.firstName && <p className="text-red-500 text-xs mt-1">{errors.firstName.message}</p>}
  //         </div>

  //         <div className="space-y-1">
  //           <label className="block text-neutral-800 dark:text-neutral-200 text-xs ml-4">Last Name</label>
  //           <input
  //             {...register("lastName")}
  //             type="text"
  //             placeholder="Last Name"
  //             className={`block w-full h-11 px-4 rounded-xl border ${errors.lastName ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
  //           />
  //           {errors.lastName && <p className="text-red-500 text-xs mt-1">{errors.lastName.message}</p>}
  //         </div>
  //       </div>

  //       <div className="space-y-1">
  //         <label className="block text-neutral-800 dark:text-neutral-200 text-xs ml-4">Email</label>
  //         <input
  //           {...register("email")}
  //           type="email"
  //           placeholder="Email address"
  //           className={`block w-full h-11 px-4 rounded-xl border ${errors.email ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
  //         />
  //         {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
  //       </div>



  //       <div className="space-y-1">
  //         <label className="block text-neutral-800 dark:text-neutral-200 text-xs ml-4">Phone</label>
  //         <input
  //           {...register("phone")}
  //           type="text"
  //           placeholder="Phone"
  //           className={`block w-full h-11 px-4 rounded-xl border ${errors.phone ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
  //         />
  //         {errors.phone && <p className="text-red-500 text-xs mt-1">{errors.phone.message}</p>}
  //       </div>

  //       <div className="space-y-1 pb-3">
  //         <label className="block text-neutral-800 dark:text-neutral-200 text-xs ml-4">Password</label>
  //         <input
  //           {...register("password")}
  //           type="password"
  //           placeholder="Password"
  //           className={`block w-full h-11 px-4 rounded-xl border ${errors.password ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
  //         />
  //         {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password.message}</p>}
  //       </div>
  //       <ButtonPrimary loading={loading} className="w-full" type="submit">Register</ButtonPrimary>
  //     </form>
  //   </div>
  // </div> : <OTPInput loading={loading} onSubmit={onVerificationSubmit} />;


  return (
    <div className={`nc-PageLogin w-full rounded-xl flex ${className}`}>
      <div className="max-w-4xl w-full rounded-lg p-2 shadow-lg flex flex-col md:flex-row">
        {/* Left Side */}
        <div className="hidden md:flex md:w-1/2 bg-gray-100 rounded-l-lg flex-col justify-center">

          <div className="relative h-full w-full rounded-lg overflow-hidden">
            <img src={care} className="object-cover h-full w-full" alt="Healthcare Image" />
            <div className="absolute inset-0 bg-black bg-opacity-20"></div>
          </div>

        </div>

        {/* Right Side */}
        <div className={`w-full md:w-1/2 p-10 text-center align-middle justify-center`}>
          {
            !isVerificationStarted ?
              <>

                <div className="justify-center mb-8">
                  <h3 className="text-[1.75rem] font-medium text-left py-[11px]">Sign up</h3>
                  <h4 className="font-normal text-left text-[0.9rem] text-gray-500">Please create your account</h4>
                </div>


                <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-8 space-y-2 text-left">
                  <div className="flex justify-between gap-3">
                    <div className="space-y-1 mb-2">
                      <label className="block text-neutral-800 dark:text-neutral-200 text-[0.85rem]">First name</label>
                      <input
                        {...register("firstName")}
                        type="text"
                        placeholder=""
                        className={`block w-full h-11 px-4 rounded-md border ${errors.firstName ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
                      />
                      {errors.firstName && <p className="text-red-500 text-xs mt-1">{errors.firstName.message}</p>}
                    </div>

                    <div className="space-y-1  mb-2">
                      <label className="block text-neutral-800 dark:text-neutral-200 text-[0.85rem]">Last name</label>
                      <input
                        {...register("lastName")}
                        type="text"
                        className={`block w-full h-11 px-4 rounded-md border ${errors.lastName ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
                      />
                      {errors.lastName && <p className="text-red-500 text-xs mt-1">{errors.lastName.message}</p>}
                    </div>
                  </div>

                  <div className="space-y-1 py-2">
                    <label className="block text-neutral-800 dark:text-neutral-200 text-[0.85rem]">Email</label>
                    <input
                      {...register("email")}
                      type="email"
                      className={`block w-full h-11 px-4 rounded-md border ${errors.email ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
                    />
                    {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
                  </div>



                  <div className="space-y-1 py-2">
                    <label className="block text-neutral-800 dark:text-neutral-200 text-[0.85rem]">Phone</label>
                    <input
                      {...register("phone")}
                      type="text"
                      className={`block w-full h-11 px-4 rounded-md border ${errors.phone ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
                    />
                    {errors.phone && <p className="text-red-500 text-xs mt-1">{errors.phone.message}</p>}
                  </div>

                  <div className="space-y-1 py-2 pb-8">
                    <label className="block text-neutral-800 dark:text-neutral-200 text-[0.85rem]">Password</label>
                    <input
                      {...register("password")}
                      type="password"
                      className={`block w-full h-11 px-4 rounded-md border ${errors.password ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
                    />
                    {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password.message}</p>}
                  </div>
                  <ButtonPrimary translate="rounded-md" loading={loading} className="w-full" type="submit">Sign up</ButtonPrimary>
                </form>
                <div className="text-[0.85rem] text-gray-500 mt-6 text-center">
                  Already registered? <span className="text-primary-500 hover:underline cursor-pointer" onClick={onCreateAccountClick}>Login</span>
                </div>

              </>
              :
              <OTPInput loading={loading} onSubmit={onVerificationSubmit} />
          }
        </div>
      </div>
      <span className="absolute right-3 top-3">
        <ButtonClose
          sizes='w-6 h-6'
          className='cursor-pointer'
          onClick={() => {
            setShowSignup(false);
          }}
        />
      </span>
    </div>
  );

};

export default PageSignup;

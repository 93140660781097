import React, { useState, useEffect } from "react";
import { Section } from "types/ProviderDetails";

const QnASections = ({ data }: { data: Section[] }) => {
  const [activeSection, setActiveSection] = useState(data[0]?.id || 0);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [activeSubSection, setActiveSubSection] = useState(
    data[0]?.subSections[0]?.id || 0
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSectionClick = (id: number) => {
    setActiveSection(id);
    // Reset active subsection when section changes
    const section = data.find((sec) => sec.id === id);
    if (section?.subSections.length) {
      setActiveSubSection(section.subSections[0].id);
    }
  };

  const handleSubSectionClick = (id: number) => {
    setActiveSubSection(id);
  };

  const activeSectionData = data.find(
    (section) => section.id === activeSection
  );

  return (
    <div className="pt-6">
      {/* Horizontal Tabs for Sections */}
      <div className="flex border-b border-gray-200 mb-6 xs:overflow-auto scrollbar-hide">
        {data.map((section) => (
          <button
            key={section.id}
            onClick={() => handleSectionClick(section.id)}
            className={`mr-6 pb-2 text-[0.85rem] ${activeSection === section.id
                ? "text-purple-700 border-b-4 border-purple-700 font-bold dark:text-white dark:border-white"
                : "text-gray-600 hover:text-gray-800 dark:text-gray-400"
              }`}
          >
            {section.sectionName.split(" ")[0]}
          </button>
        ))}
      </div>

      <div className="flex xs:flex-col md:flex-row">
        {/* Vertical Tabs for SubSections */}
        <div className="xs:w-full md:w-1/4 ">
          <div className="flex xs:flex-row md:flex-col rounded-lg xs:overflow-auto scrollbar-hide overflow-hidden border-gray-200 dark:border-none">
            {activeSectionData?.subSections.map((subSection) => (
              <button
                key={subSection.id}
                onClick={() => handleSubSectionClick(subSection.id)}
                className={`p-4 text-left text-[0.85rem] rounded-lg whitespace-nowrap ${activeSubSection === subSection.id
                    ? "text-purple-700 bg-gray-100 font-semibold dark:text-white dark:bg-gray-800"
                    : "text-gray-600 hover:bg-gray-50 dark:text-gray-400"
                  }`}
              >
                {subSection.subSectionName.match(/(Features|Information|Overview|Services)/i) ? subSection.subSectionName.split(" ")[0] : subSection.subSectionName}
              </button>
            ))}
          </div>
        </div>

        {/* SubSection Content */}
        <div className="xs:w-full md:w-3/4 xs:pl-0 xs:pt-3 md:pl-6 md:pt-0 overflow-auto h-[500px] scrollbar-hide">
          {activeSectionData?.subSections
            .filter((subSection) => subSection.id === activeSubSection)
            .map((subSection) => (
              <div key={subSection.id}>
                {subSection.questions.map((question) => (
                  <div
                    key={question.id}
                    className="mb-4 bg-gray-100 p-4 rounded-lg dark:bg-gray-800"
                  >
                    <p className="font-medium text-[0.85rem]">
                      {question.questionText.replace(":", "")}
                    </p>
                    <ul className="list-disc pl-5 mt-2">
                      {question.responses.length > 0 ? (
                        question.responses.map((response) => (
                          <li
                            key={response.id}
                            className="text-gray-700 text-[0.85rem] dark:text-gray-400"
                          >
                            {response.responseText}
                          </li>
                        ))
                      ) : (
                        <li className="text-gray-500 text-[0.85rem] dark:text-gray-400">
                          No responses available
                        </li>
                      )}
                    </ul>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default QnASections;

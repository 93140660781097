import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import FooterLogo from "shared/Logo/FooterLogo";
import uiUseStore from "store/UIStore";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "4",
    title: "Company",
    menus: [
      { href: "#", label: "About us" },
      { href: "#", label: "Terms of use" },
      { href: "#", label: "Privacy policy" }
    ]
  },
  {
    id: "5",
    title: "Discover",
    menus: [
      { href: "#", label: "Pricing & Packages" },
      { href: "#", label: "Spot care for Business" },
      { href: "#", label: "Blog" }
    ]
  },
  {
    id: "6",
    title: "Providers",
    menus: [
      { href: "#", label: "List your business" },
      // { href: "#", label: "Report an issue" }
    ]
  },
  {
    id: "7",
    title: "Support",
    menus: [
      { href: "/contact", label: "Contact Us" },
      { href: "#", label: "Support@spot.care" }      
    ]
  }
];

const Footer: React.FC = () => {
  const {
    setIsReportDialogOpen,
    setDialogProviderCode,
    setDialogProviderName
  } = uiUseStore();
  const handleReportIssueClick = () => {
    setIsReportDialogOpen(true);
    setDialogProviderCode("HOME");
    setDialogProviderName("Report An Issue");
  };
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-600 dark:text-neutral-300 hover:text-primary-500 dark:hover:text-primary-500 justify-center"
                href={item.label === "Support@spot.care" ? "mailto:support@spot.care" : item.href}
                onClick={(e) => {
                  if (item.label === "Report an issue") {
                    e.preventDefault();
                    handleReportIssueClick();
                  }
                }}
              >
                {item.label === "Support@spot.care" &&  <i className="las la-envelope text-primary-500 dark:text-primary-400 text-[1rem] mr-2"></i>}
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>
    <div className="nc-Footer xs:p-5 sm:p-8 lg:pl-20 lg:pr-20 relative w-full border-t border-gray-200 dark:border-gray-500">
      <div className="grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 xs:mb-10 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 lg:gap-x-10 md:py-24 lg:py-10 lg:px-10">
        <FooterLogo />
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <span className="w-full flex justify-center text-center xs:text-left sm:text-center md:text-center lg:text-center text-xs font-thin text-gray-400">
        Spot.care is a service provided by Pellucid Labs, LLC.
        <br />
        Spot.care does not employ any caregiver or provider and is not
        responsible for the conduct of any user of our site. All information
        shown on our site is not verified by Spot.care.
        <br />
        You need to do your own diligence to ensure the provider or caregiver
        you choose is appropriate for your needs and complies with applicable
        laws.
      </span>

      
    </div>
    <hr className="w-full border-t border-gray-300" />

    <span className="w-full flex justify-center text-center text-sm font-thin text-gray-400 py-6">
      &#169; 2024 Spot.care - All rights reserved.
    </span>
    </>
  );
};

export default Footer;

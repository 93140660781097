import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import StartRating from "components/StartRating/StartRating";
import { ContainerMap } from "components/GoogleMap/ContainerMap";
import { Providers } from "types/ProviderDetails";
import appStore from "store/AppStore";
import {
  BuildingOfficeIcon,
  CheckBadgeIcon,
  EnvelopeIcon,
  ExclamationCircleIcon,
  FlagIcon,
  GlobeAltIcon,
  MapPinIcon,
  PhoneIcon
} from "@heroicons/react/24/outline";
import ButtonThird from "shared/Button/ButtonThird";
import Badge from "shared/Badge/Badge";
import { buildTenantDetails } from "utils/tenant";
import { TitleCase, formatPhoneNumber } from "utils/converter";
import ImageSlider from "components/ImageSlider/ImageSlider";
import uiUseStore from "store/UIStore";
import QnASections from "./QnASections";
import Tooltip from "shared/Tooltip/Tooltip";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { APP_CONSTANTS } from "constants/AppConstants";
import ReviewSection from "./ReviewSection";
import { KEYS } from "constants/KeyConstants";

interface ProviderDialogProps {
  open: boolean;
  closeModal: (value: boolean) => void;
  details: Providers;
}

export const ProviderDialog: React.FC<ProviderDialogProps> = ({
  open,
  closeModal,
  details
}) => {
  const [activeTab, setActiveTab] = useState("Facts");

  useEffect(() => {
    if (details?.sections && details.sections.length > 0) {
      setActiveTab("Facts");
    } else {
      setActiveTab("Review");
    }
  }, [details, open]);

  const { setSavedProviderList, savedProviderList, careTypes } = appStore();
  const {
    setIsReportDialogOpen,
    setDialogProviderName,
    setDialogProviderCode,
    setIsForceLogin
  } = uiUseStore();

  function isCareTypePresent(input: string): boolean {
    return careTypes.some((group) => group.careTypes.includes(input));
  }

  const isSelected = savedProviderList
    ? savedProviderList?.filter((profile) => profile.name === details.name)
        .length > 0
    : false;

  const savedProviders = () => {
    const isLoggedIn = localStorage.getItem(KEYS.ISLOGGEDIN) === "true";
    if (isLoggedIn) {
      if (!isSelected) {
        if (savedProviderList) {
          setSavedProviderList(savedProviderList.concat(details));
        } else {
          setSavedProviderList([details]);
        }
      } else {
        if (savedProviderList) {
          setSavedProviderList(
            savedProviderList?.filter((detail) => detail.name !== details.name)
          );
        }
      }
    } else {
      setIsForceLogin(true);
    }
  };

  const renderInformation = () => {
    return (
      <div className="py-2">
        <div className="mt-6 grid xs:grid-cols-1 md:grid-cols-2 xs:space-y-5 md:space-y-0">
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <BuildingOfficeIcon className="h-6 w-6 text-neutral-500 dark:text-neutral-400" />
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {details.locations[0].address}, {details.locations[0].city},{" "}
                {details.locations[0].state},{" "}
                {details.locations[0].postalCode.split("-")[0]}
              </span>
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <PhoneIcon className="h-5 w-5 text-neutral-500 dark:text-neutral-400" />
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {details.phone
                  ? details.phone
                      .split(",")
                      .map((item) => formatPhoneNumber(item))
                      .join(", ")
                  : "Unavailable"}{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="mt-6 grid xs:grid-cols-1 md:grid-cols-2 xs:space-y-5 md:space-y-0">
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <MapPinIcon className="h-6 w-6 text-neutral-500 dark:text-neutral-400" />
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {details.distanceInMiles} miles away
              </span>
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <EnvelopeIcon className="h-5 w-5 text-neutral-500 dark:text-neutral-400" />
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {details.email && details.email !== ""
                  ? details.email
                  : "Unavailable"}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-6 grid xs:grid-cols-1 md:grid-cols-2 xs:space-y-5 md:space-y-0">
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <GlobeAltIcon className="h-6 w-6 text-neutral-500 dark:text-neutral-400" />
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {details.website && details.website.trim() !== ""
                  ? details.website
                  : "Unavailable"}
              </span>
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex items-center space-x-3 pl-2 xs:pl-1">
              <StartRating
                point={details.rating?.overall}
                reviewCount={5}
                className="text-3xl"
              />
            </div>
          </div>
        </div>
        <div
          className={`mt-6 xs:h-96 md:h-64 grid xs:grid-cols-1 ${
            details.images && details.images.length > 0
              ? `md:grid-cols-2`
              : `md:grid-cols-1`
          }  gap-4`}
        >
          {details.images && details.images.length > 0 && (
            <div className="relative rounded-xl overflow-hidden">
              <ImageSlider
                images={
                  details.images && details.images.length > 0
                    ? details.images.map((img) => img.imagePath)
                    : [APP_CONSTANTS.DUMMY_IMAGE]
                }
              />
            </div>
          )}

          <div
            className={`rounded-xl overflow-hidden md:h-full ${
              details.images && details.images.length > 0
                ? "xs:h-44"
                : "xs:h-full"
            }`}
          >
            <ContainerMap markers={details} />
          </div>
        </div>
      </div>
    );
  };

  const cmsRating = () => {
    return (
      <div className="py-7">
        <h3 className="text-lg font-medium">
          CMS Quality Ratings
          <span className="ml-2 text-xs text-primary-900 dark:text-primary-300">
            <a
              href={details.rating?.moreinfo}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              medicare.gov
              <i className="las la-external-link-alt ml-1"></i>
            </a>
          </span>
        </h3>
        <div className="mt-6 relative ">
          <div className="mt-6 grid xs:grid-cols-2 md:grid-cols-3">
            <div className="space-y-3 mb-5">
              <div className=" items-center space-y-2">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  Overall Rating
                </span>
                <StartRating point={details.rating?.overall} reviewCount={5} />
              </div>
            </div>
            <div className="space-y-3 mb-5">
              <div className="space-y-3">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  Health Inspection
                </span>
                <StartRating
                  point={details.rating?.healthInspection}
                  reviewCount={5}
                />
              </div>
            </div>
            <div className="space-y-3 mb-5">
              <div className="space-y-3">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  Quality Measure
                </span>
                <StartRating
                  point={details.rating?.qualityMeasure}
                  reviewCount={5}
                />
              </div>
            </div>
            <div className="space-y-3 mb-5">
              <div className="space-y-3">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  Staff Rating
                </span>
                <StartRating
                  point={details.rating?.staffRating}
                  reviewCount={5}
                />
              </div>
            </div>
            <div className="space-y-3">
              <div className="space-y-3">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  Long Stay Quality Mesaure
                </span>
                <StartRating
                  point={details.rating?.longStayQuality}
                  reviewCount={5}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const colors = ["indigo"];

  const renderServices = () => {
    return (
      <div className="py-4">
        <h3 className="text-lg font-medium">Services Available</h3>
        <div className="mt-6 relative ">
          <div className="mt-6 flex flex-wrap gap-2">
            {details.services.map((profile) => {
              if (isCareTypePresent(profile)) {
                return (
                  <Badge
                    name={
                      <div className="flex items-center">
                        <span className="m-1 text-sm text-center">
                          {profile}
                        </span>
                      </div>
                    }
                    color={colors[Math.floor(Math.random() * colors.length)]}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  };

  const handleCloseMenu = () => {};

  const createReviewAndFeaturesTabs = () => {
    return (
      <div className="pt-5 mx-auto h-fit">
        <ul
          className="flex flex-wrap -mb-px text-sm font-medium text-center"
          id="default-tab"
          data-tabs-toggle="#default-tab-content"
          role="tablist"
        >
          {details && details.sections && details.sections?.length > 0 && (
            <li className="me-2" role="presentation">
              <button
                className={`inline-block py-4 pe-4 border-b-2 rounded-t-lg ${
                  activeTab === "Facts"
                    ? "border-blue-500"
                    : "border-transparent"
                }`}
                id="facts-tab"
                data-tabs-target="#Facts"
                onClick={() => setActiveTab("Facts")}
                type="button"
                role="tab"
                aria-controls="Facts"
                aria-selected={activeTab === "Facts"}
              >
                Facts & Features
              </button>
            </li>
          )}

          {details.totalReview && (
            <li className="me-2" role="presentation">
              <button
                className={`inline-block py-4 pe-4 border-b-2 rounded-t-lg ${
                  activeTab === "Review"
                    ? "border-blue-500"
                    : "border-transparent"
                }`}
                id="review-tab"
                data-tabs-target="#Review"
                onClick={() => setActiveTab("Review")}
                type="button"
                role="tab"
                aria-controls="Review"
                aria-selected={activeTab === "Review"}
              >
                Reviews
              </button>
            </li>
          )}
        </ul>
        <div id="default-tab-content">
          {details && details.sections && details.sections?.length > 0 && (
            <div
              className={activeTab === "Facts" ? "" : "hidden"}
              id="Facts"
              role="tabpanel"
              aria-labelledby="facts-tab"
            >
              <QnASections data={details.sections ?? []} />
            </div>
          )}

          {details.totalReview && (
            <div
              className={activeTab === "Review" ? "" : "hidden"}
              id="Review"
              role="tabpanel"
              aria-labelledby="review-tab"
            >
              <ReviewSection data={details.totalReview} />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className=" w-screen fixed inset-0 z-50 overflow-y-auto"
        onClose={handleCloseMenu}
      >
        <div className="min-h-screen min-w-full text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            className="inline-block py-8 px-2 h-screen w-full max-w-full"
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-flex flex-col w-full max-w-5xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
              <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <h2 className="xs:text-lg xs:w-80 md:w-auto md:text-xl font-medium capitalize inline-flex dark:text-white">
                    {TitleCase(details.name!)}
                    {details.rating?.overall! >= 5 && (
                      <Tooltip text={"Verified"}>
                        <CheckBadgeIcon className="h-7 w-7 mr-1 text-emerald-500 dark:text-emerald-100 ml-1" />
                      </Tooltip>
                    )}
                    {details.rating?.overall! <= 2 && (
                      <Tooltip text={"Poorly rated"}>
                        <ExclamationCircleIcon className="h-7 w-7 mr-1 text-red-500 dark:text-red-500 ml-1" />
                      </Tooltip>
                    )}
                  </h2>
                </Dialog.Title>
                <span className="absolute right-3 top-3">
                  <ButtonClose
                    onClick={() => {
                      closeModal(false);
                    }}
                  />
                </span>
                <span className="absolute left-4 top-4 xs:hidden sm:block">
                  {details.isPreffered && (
                    <Badge
                      name={
                        <div className="flex items-center">
                          <img
                            src={buildTenantDetails().logo.symbol}
                            className="h-4 w-4"
                            alt="load again"
                          />
                          <span className="ml-1 text-sm">Recommended</span>
                        </div>
                      }
                      color="purple"
                    />
                  )}
                </span>
              </div>

              <div className="flex-grow overflow-y-auto scrollbar-hide">
                <div className="xs:px-2 md:px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                  {renderInformation()}
                  {renderServices()}
                  {details.isRatingsAviable && cmsRating()}

                  {createReviewAndFeaturesTabs()}
                </div>
              </div>

              <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                <ButtonThird
                  onClick={() => {
                    setIsReportDialogOpen(true);
                    setDialogProviderName(details.name);
                    setDialogProviderCode(details.code);
                  }}
                  className="bg-red-500 hover:bg-red-700 px-4 py-2 border text-white border-none outline-0 xs:text-xs"
                >
                  <FlagIcon className="h-4 w-4 ml-1 mr-1 stroke-current stroke-2" />
                  Report an issue
                </ButtonThird>

                <ButtonPrimary
                  onClick={savedProviders}
                  className={`${
                    isSelected
                      ? "bg-red-500 hover:bg-red-700"
                      : "bg-primary-500 hover:bg-primary-700"
                  } px-4 py-2 border text-white border-none outline-0 xs:text-xs`}
                >
                  {isSelected ? "Remove from " : "Add to "}wishlist
                </ButtonPrimary>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

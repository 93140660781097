import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import StandardHeader from "components/Header/StandardHeader";

let OPTIONS = {
  root: null,
  rootMargin: "0px",
  threshold: 1.0
};

let OBSERVER: IntersectionObserver | null = null;

const SiteHeader = () => {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const [isTopOfPage, setIsTopOfPage] = React.useState(window.pageYOffset < 5);
  const location = useLocation();

  const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      setIsTopOfPage(entry.isIntersecting);
    });
  };

  useEffect(() => {
    // disconnect the observer
    if (!OBSERVER) {
      OBSERVER = new IntersectionObserver(intersectionCallback, OPTIONS);
      anchorRef.current && OBSERVER.observe(anchorRef.current);
    }
  }, [location.pathname]);

  const renderHeader = () => {
    return (
      <StandardHeader
        className={"shadow-sm dark:border-b dark:border-neutral-700"}
      />
    );
  };

  return (
    <>
      {renderHeader()}
    </>
  );
};

export default SiteHeader;

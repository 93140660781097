import { KEYS } from "constants/KeyConstants";
import { useState, useRef, FormEvent, KeyboardEvent, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Services } from "services/service";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import appStore from "store/AppStore";
import uiUseStore from "store/UIStore";
import useCookie from "utils/useCookies";

const PageOTP: React.FC = () => {
    const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
    const [email, setEmail] = useState("")
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const { loading, setLoader } = appStore();
    const { setIsForceLogin } = uiUseStore();
    const { getCookie, removeCookie } = useCookie();
    const navigate = useNavigate();

    useEffect(() => {
        let savedEmail = getCookie(KEYS.EMAIL);
        if (savedEmail) {
            setEmail(savedEmail)
            resendVerification();
        }
        else {
            navigate('/')
        }
    }, [])

    const resendVerification = async () => {
        try {
            setLoader(true);
            let result = await Services.RetryVerification(getCookie(KEYS.EMAIL) ?? "");
            console.log(result);
            removeCookie(KEYS.EMAIL);
            removeCookie(KEYS.VERIFICATIONPENDING);
            setLoader(false);

        } catch (error) {
            toast.error('verification failed')
        }
    }

    const handleChange = (value: string, index: number) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Move to the next input if there’s a value and it's not the last box
        if (value && index < 3) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handleSubmit = async (e: FormEvent) => {

        try {
            e.preventDefault();

            setLoader(true);
            let result = await Services.Verify(otp.join(""), email);

            console.log(result);
            removeCookie(KEYS.EMAIL);
            removeCookie(KEYS.VERIFICATIONPENDING);
            setLoader(false);
            navigate('/')            
            setIsForceLogin(true);
        } catch (error) {
            toast.error('verification failed')
        }



    };

    return (
        <div className="nc-OTPInput lg:min-w-96 max-w-max" data-nc-id="OTPInput">
            <h2 className="my-10 flex items-center text-3xl leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                Enter the Verification code sent to {email.split("").slice(0, 3)}******{email.split("").slice(email.split("").length - 6, email.split("").length)}
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                <span className="text-gray-800">A verification code has sent your email.</span>
                <div className="flex justify-center space-x-4">
                    {otp.map((_, index) => (
                        <input
                            key={index}
                            type="text"
                            value={otp[index]}
                            onChange={(e) => handleChange(e.target.value, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            ref={(el) => (inputRefs.current[index] = el)}
                            maxLength={1}
                            className={`w-12 h-12 text-center border border-gray-300 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:bg-neutral-900 text-sm font-semibold text-neutral-900 dark:text-neutral-100 ${otp[index] ? "border-primary-300" : "border-gray-300"
                                }`}
                        />
                    ))}
                </div>
                <div className="flex items-center justify-center mt-6">
                    <ButtonPrimary loading={loading} className="w-full" type="submit">
                        Verify OTP
                    </ButtonPrimary>
                    <ButtonPrimary loading={loading} className="w-full" type="button" onClick={resendVerification}>
                        Resend Code
                    </ButtonPrimary>
                </div>
            </form>
        </div>
    );
};

export default PageOTP;

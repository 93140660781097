import { Review, TotalReview } from "types/ProviderDetails";
import { StarIcon, UserGroupIcon } from "@heroicons/react/24/solid";
import Avatar from "shared/Avatar/Avatar";
import StartRating from "components/StartRating/StartRating";

const ReviewSection = ({ data }: { data: TotalReview }) => {
  const ReviewHeaderSection = () => {
    return (
      <div className="w-full flex items-center justify-center gap-24">
        <div className="flex flex-col items-center justify-center gap-2">
          <div className="p-3 bg-yellow-200 rounded-full">
            <StarIcon className="h-5 w-5 text-yellow-500 dark:text-yellow-400" />
          </div>
          <p className="font-semibold text-sm">{data.totalRating} Rating</p>
        </div>
        <div className="flex flex-col items-center justify-center gap-2">
          <div className="p-3 bg-green-300 rounded-full">
            <UserGroupIcon className="h-5 w-5 text-green-600 dark:text-green-600" />
          </div>
          <p className="font-semibold text-sm">{data.totalReviews}</p>
        </div>
      </div>
    );
  };

  const CommentListing = ({ data }: { data: Review }) => {
    return (
      <div className="w-full flex items-start justify-start gap-6 py-4">
        <Avatar
          sizeClass="h-10 w-10 text-lg mt-1"
          radius="rounded-full"
          imgUrl={data.userThumbnail}
        />
        <div className="flex flex-col items-start justify-start w-full gap-4">
          <div className="flex flex-row items-start justify-between w-full">
            <div className="flex flex-col items-start justify-start">
              <p className="text-sm font-semibold">{data.username}</p>
              <p className="text-sm text-neutral-500 dark:text-neutral-400">
                {data.reviewPeriod}
              </p>
            </div>
            <StartRating
              reviewCount={5}
              point={parseInt(data.rating) ?? 0}
              isSelector={false}
            />
          </div>
          <p className="text-neutral-6000 dark:text-neutral-300">
            {data.review}
          </p>
        </div>
      </div>
    );
  };

  const ReviewContentSection = () => {
    return (
      <div className="divide-y divide-neutral-100 dark:divide-neutral-800 w-full">
        {data.review.map((e, index) => (
          <CommentListing key={index} data={e} />
        ))}
      </div>
    );
  };

  return (
    <div className="pt-6 flex flex-col items-start justify-start gap-5 w-full">
      {ReviewHeaderSection()}
      {ReviewContentSection()}
    </div>
  );
};

export default ReviewSection;

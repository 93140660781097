import React, { useEffect, useRef } from 'react';
import confetti from 'canvas-confetti';
import ButtonClose from 'shared/ButtonClose/ButtonClose';

interface SuccessDialogProps {
    setIsSuccessDialogOpen: (state: boolean) => void;
}

const SuccessDialog: React.FC<SuccessDialogProps> = ({ setIsSuccessDialogOpen }) => {
    useEffect(() => {
        // Trigger confetti when the dialog is shown
        confetti({
            particleCount: 100,
            spread: 200,
            origin: { y: 0.5, x: 0.5 },
            angle: 45,
        });
    }, []);

    return (
        <div className="text-neutral-700 dark:text-neutral-300 w-full flex justify-between px-5 p-1 align-middle relative bg-green-50 rounded-md">
            <p className="text-base font-normal text-center text-green-400 p-1">
                Email sent successfully
            </p>
            <ButtonClose className='text-gray-300 dark:text-gray-600' onClick={() => setIsSuccessDialogOpen(false)} />
        </div>
    );
};


export default SuccessDialog;

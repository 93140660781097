import { TenantDetails } from "types/tenant";
import WellstarSymbol from "../images/app/wellstar/symbol.png";
import WellstarFullLogo from "../images/app/wellstar/full.png";
import WellstarSemiLogo from "../images/app/wellstar/semi.png";
import WellstardarkLogo from "../images/app/wellstar/dark.png";

import Symbol from "../images/app/drd/symbol.png";
import FullLogo from "../images/app/drd/full.png";
import SemiLogo from "../images/app/drd/semi.png";
import darkLogo from "../images/app/drd/dark.png";

import zocDocSymbol from "../images/app/zocdoc/symbol.png";
import zocDocFullLogo from "../images/app/zocdoc/full.png";
import zocDocSemiLogo from "../images/app/zocdoc/semi.png";
import zocDocDarkLogo from "../images/app/zocdoc/dark.png";

import careSymbol from "../images/app/care/symbol.png";
import careFullLogo from "../images/app/care/full.png";
import careSemiLogo from "../images/app/care/semi.png";
import careDarkLogo from "../images/app/care/semi.png";

import spotSymbol from "../images/app/spot/symbol.png";
import spotFullLogo from "../images/app/spot/full.png";
import spotSemiLogo from "../images/app/spot/semi.png";
import spotDarkLogo from "../images/app/spot/dark.png";

export enum Tenant {
    Spot = "spot",
    Wellstar = "wellstar",
    Default = "default",
    Zocdoc = "zocdoc",
    Care = "care"
}

export const buildTenantDetails = (): TenantDetails => {
    return {
        logo: {
            full: WellstarFullLogo,
            semi: WellstarSemiLogo,
            symbol: WellstarSymbol,
            dark: WellstardarkLogo
        }
    }
}

export const buildDefaultTenantDetails = (): TenantDetails => {
    return {
        logo: {
            full: FullLogo,
            semi: SemiLogo,
            symbol: Symbol,
            dark: darkLogo
        }
    }
}

export const buildZocdocTenantDetails = (): TenantDetails => {
    return {
        logo: {
            full: zocDocFullLogo,
            semi: zocDocSemiLogo,
            symbol: zocDocSymbol,
            dark: zocDocDarkLogo
        }
    }
}

export const buildSpotTenantDetails = (): TenantDetails => {
    return {
        logo: {
            full: spotFullLogo,
            semi: spotSemiLogo,
            symbol: spotSymbol,
            dark: spotDarkLogo,
        }
    }
}

export const buildCareTenantDetails = (): TenantDetails => {
    return {
        logo: {
            full: careFullLogo,
            semi: careSemiLogo,
            symbol: careSymbol,
            dark: careDarkLogo
        }
    }
}

export const useTenantConfigs = (tenant: any): TenantDetails => {
    switch (tenant) {
        case Tenant.Default:
            return buildDefaultTenantDetails();
        case Tenant.Wellstar:
            return buildTenantDetails();
        case Tenant.Zocdoc:
            return buildZocdocTenantDetails();
        case Tenant.Care:
            return buildCareTenantDetails();
        case Tenant.Spot:
            return buildSpotTenantDetails();
        default:
            return buildSpotTenantDetails();
    }
}
import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import uiUseStore from "store/UIStore";

const SearchTab = () => {
  const { setShowHeroMobileSearch } = uiUseStore();
  const heroSearchFormMobile = () => {
    setShowHeroMobileSearch(true);
  };
  return (
    <button
      onClick={heroSearchFormMobile}
      className="text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none xs:flex items-center justify-center"
    >
      <MagnifyingGlassIcon className="w-6 h-6" aria-hidden="true" />
    </button>
  );
};

export default SearchTab;

import { StarIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";

export interface StartRatingProps {
  className?: string;
  point?: number;
  reviewCount?: number;
  isSelector?: boolean;
}

const StartRating: FC<StartRatingProps> = ({
  className = "",
  point = 4.5,
  reviewCount = 112,
  isSelector = true,
}) => {
  return (
    <div
      className={`nc-StartRating flex items-center space-x-1 text-sm  ${className}`}
      data-nc-id="StartRating"
    >
      <div className="pb-[2px] flex flex-row items-center justify-start">
        {[...Array(5)].map((_, index) => (
          <StarIcon
            key={index}
            className={`w-[18px] h-[18px] ${
              index < point ? "text-orange-500" : "text-gray-400"
            }`}
          />
        ))}
      </div>
      {isSelector && (
        <span className="text-neutral-500 dark:text-neutral-400">
          ({point})
        </span>
      )}
    </div>
  );
};

export default StartRating;

import React, { useRef, useState } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import uiUseStore from "store/UIStore";
import { Services } from "services/service";

interface reportIssueListProps {
  id: string;
  reports: string;
}

const ReportIssueDialog = () => {
  const {
    dialogProviderName,
    isDarkUi,
    setIsReportDialogOpen,
    dialogProviderCode,
    setIsReportSuccessDialogOpen
  } = uiUseStore();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const reportRef = useRef<HTMLInputElement>(null);

  const handleReportDialogClose = () => {
    setIsReportDialogOpen(false);
  };

  const saveReport = async () => {
    await Services.SaveReport(
      dialogProviderCode ?? "",
      selectedOption ?? "",
      reportRef.current?.value ?? ""
    );
    setIsReportDialogOpen(false);
    setIsReportSuccessDialogOpen(true);
  };

  var reportIssueList: reportIssueListProps[] = [
    { id: "radio1", reports: "Permanently closed" },
    { id: "radio2", reports: "Incorrect information" },
    { id: "radio3", reports: "Moved elsewhere" },
    { id: "radio4", reports: "Duplicate" },
    { id: "radio5", reports: "Spam" },
    { id: "radio6", reports: "Other" }
  ];

  return (
    <div className="relative">
      <div
        className="nc-ReportIssue w-full rounded-xl flex"
        data-nc-id="ReportIssue"
      >
        <div className="flex-1 justify-center items-center w-full space-y-6">
          <div className="flex flex-row text-neutral-700 dark:text-neutral-300 text-center justify-between items-center border-b dark:border-neutral-800 py-3 px-2 relative">
            <p className="text-xl font-semibold flex-grow">
              {dialogProviderName}
            </p>
            <span className="flex-shrink-0">
              <ButtonClose onClick={handleReportDialogClose} />
            </span>
          </div>
          <div className="md:w-98 xs:w-80 flex flex-col text-neutral-700 dark:text-neutral-300 justify-center items-start gap-y-6 px-6 pt-6 pb-5">
            <p className="font-semibold">Report this provider</p>
            {reportIssueList.map((e) => (
              <div className="flex flex-row items-center">
                <input
                  id={e.id}
                  type="radio"
                  name="radio"
                  className="hidden radioBoxShadow"
                  checked={e.reports === selectedOption}
                />
                <label
                  htmlFor={e.id}
                  className="flex items-center cursor-pointer gap-x-2"
                  onClick={() => {
                    setSelectedOption(e.reports);
                  }}
                >
                  <span
                    className={`w-4 h-4 inline-block rounded-full border border-grey ${
                      isDarkUi
                        ? "shadow-radio-dark-custom-inset"
                        : "shadow-radio-white-custom-inset"
                    }`}
                  ></span>
                  {e.reports}
                </label>
              </div>
            ))}
            <div className="w-full flex flex-col justify-center items-center gap-y-6">
              <div className="w-full">
                <span className="font-semibold text-neutral-800 dark:text-neutral-200">
                  Report something else
                </span>
                <Input
                  placeholder="Report something else"
                  className="mt-2"
                  rounded="rounded-lg"
                  ref={reportRef}
                />
              </div>
              <div className="w-full flex flex-row justify-between items-center">
                <ButtonSecondary
                  className="rounded"
                  onClick={handleReportDialogClose}
                >
                  Cancel
                </ButtonSecondary>
                <ButtonPrimary
                  className="rounded p-1"
                  onClick={async () => {
                    await saveReport();
                  }}
                >
                  Submit
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportIssueDialog;

import React, { useState } from "react";

interface TooltipProps {
  text: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative flex items-center">
      {/* Target Element (Trigger) */}
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>

      {/* Tooltip */}
      {isVisible && (
        <div className="absolute left-full mb-1 w-max bg-gray-500 text-white text-sm rounded py-1 px-2 shadow-lg">
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;

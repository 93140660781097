import Label from "shared/Label/Label";
import React, { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import { KEYS } from "constants/KeyConstants";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Services } from "services/service";
import appStore from "store/AppStore";
import uiUseStore from "store/UIStore";
import { ArrowUpTrayIcon } from "@heroicons/react/24/solid";

export interface AccountPageProps {
  className?: string;
}

interface UserDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  profilePicture: string;
}

interface Errors {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {

  const { userDetail, setUserDetail, loading, setLoader, setProfileImage } = appStore();
  const { setIsForceLogin } = uiUseStore();
  const navigate = useNavigate();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [formData, setFormData] = useState<UserDetails>({
    firstName: userDetail.firstName || '',
    lastName: userDetail.lastName || '',
    email: userDetail.email || '',
    phone: userDetail.phone || '',
    profilePicture: userDetail.profilePicture || '',
  });

  const [profilePictureBlob, setProfilePictureBlob] = useState<Blob | null>(null);
  const [errors, setErrors] = useState<Errors>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = async () => {
    const email = localStorage.getItem(KEYS.EMAIL);
    if (email) {
      const result = await Services.GetUserByEmail(email);
      if (result) {
        setFormData({
          email: result.email,
          firstName: result.firstName,
          lastName: result.lastName,
          phone: result.phone,
          profilePicture: result.profilePicture ?? '',
        });
        setProfileImage(result.profilePicture ?? '');
        setIsForceLogin(false);
      } else {
        setIsForceLogin(true);
        navigate('/');
      }
    } else {
      setIsForceLogin(true);
      navigate('/');
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleProfilePictureChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const blob = new Blob([file], { type: file.type });
      setProfilePictureBlob(blob);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, profilePicture: reader.result as string });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAvatarClick = () => {
    fileInputRef.current?.click();
  };

  const validate = (): boolean => {
    const newErrors: Errors = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    };

    if (!formData.firstName.trim()) newErrors.firstName = 'First name is required';
    if (!formData.lastName.trim()) newErrors.lastName = 'Last name is required';
    if (!formData.email.trim()) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';
    if (!formData.phone.trim()) newErrors.phone = 'Phone number is required';
    else if (!/^\d{10}$/.test(formData.phone)) newErrors.phone = 'Phone number must be 10 digits';

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (validate()) {
      setLoader(true);
      try {
        const result = await Services.updateProfile(
          profilePictureBlob,
          formData.firstName,
          formData.lastName,
          formData.phone,
          formData.email
        );
        setProfileImage(result?.profilePicture ?? '');
        toast.success('Profile updated successfully!');
        setUserDetail(formData);
      } catch (error) {
        toast.error('Failed to update profile.');
      } finally {
        setLoader(false);
      }
    }
  };



  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Spot.care | Account</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div onClick={handleAvatarClick} className="relative rounded-full overflow-hidden flex cursor-pointer">
                <Avatar imgUrl={formData.profilePicture} sizeClass="w-32 h-32" />
                <div className="absolute inset-0 bg-black bg-opacity-60 hidden group-hover:flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-xs">Change Image</span>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleProfilePictureChange}
                  ref={fileInputRef}
                  className="hidden"
                />
              </div>
            </div>
            <form className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6" onSubmit={handleSubmit}>
              <div>
                <label className="block text-sm font-medium text-gray-600">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border rounded-md bg-gray-100 focus:outline-none"
                />
                {errors.firstName && <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>}
              </div>
              {/* ---- */}
              <div>
                <label className="block text-sm font-medium text-gray-600">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border rounded-md bg-gray-100 focus:outline-none"
                />
                {errors.lastName && <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>}
              </div>
              {/* ---- */}
              <div>
                <label className="block text-sm font-medium text-gray-600">Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  disabled
                  className="w-full mt-1 p-2 border rounded-md bg-gray-100 focus:outline-none"
                />
                {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-600">Phone</label>
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border rounded-md bg-gray-100 focus:outline-none"
                />
                {errors.phone && <p className="text-red-500 text-xs mt-1">{errors.phone}</p>}
              </div>
              <div className="pt-2">
                <ButtonPrimary loading={loading}>Update info</ButtonPrimary>
              </div>
            </form>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { APP_CONSTANTS } from "constants/AppConstants";
import React, { useState } from "react";

interface ImageSliderProps {
  images: string[]; // Array of image URLs
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstImage = currentIndex === 0;
    const newIndex = isFirstImage ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastImage = currentIndex === images.length - 1;
    const newIndex = isLastImage ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = APP_CONSTANTS.DUMMY_IMAGE;
    setCurrentIndex(currentIndex);
  };

  return (
    <div className="flex items-center justify-center w-full h-full">
      {
        images.length > 1 &&
        <button
          onClick={goToPrevious}
          className="absolute left-2 bg-white hover:bg-gray-400 text-gray-800 font-bold py-1 px-1 rounded-full"
        >
          <ChevronLeftIcon className="h-4" />
        </button>
      }

      <img
        src={images[currentIndex]}
        alt={`Slide ${currentIndex}`}
        onError={handleError}
        className="object-cover md:rounded w-full xs:h-48 md:h-full"
      />
      {
        images.length > 1 &&
        <button
          onClick={goToNext}
          className="absolute right-2 bg-white hover:bg-gray-400 text-gray-800 font-bold py-1 px-1 rounded-full"
        >
          <ChevronRightIcon className="h-4" />
        </button>
      }
    </div>
  );
};

export default ImageSlider;

import React, { FC, useEffect, useRef, useState, Fragment } from "react";
import Logo from "shared/Logo/Logo";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import { useLocation, useNavigate } from "react-router-dom";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import AvatarDropdown from "./AvatarDropdown";
import MenuBar from "shared/MenuBar/MenuBar";
import { StaySearchFormField } from "components/HeroSearchForm/type";
import HeroSearchFormSmall from "components/HeroSearchForm/HeroSearchFormSmall";
import SavedProviders from "shared/SavedProviders/SavedProviders";
import uiUseStore from "store/UIStore";
import ThemeDropdown from "./ThemeDropdown";
import appStore from "store/AppStore";
import { useTenantConfigs as UseTenantConfigs } from "utils/tenant";
import SearchTab from "./SearchTab";
import ButtonOutlined from "shared/Button/ButtonOutlined";
import { KEYS } from "constants/KeyConstants";
interface StandardHeaderProps {
  className?: string;
}

let WIN_PREV_POSITION = window.pageYOffset;

const StandardHeader: FC<StandardHeaderProps> = ({ className = "" }) => {
  const headerInnerRef = useRef<HTMLDivElement>(null);
  const {
    isHomePage,
    setIsShowCareVeticalLine,
    setIsHeaderBorderVisible,
    servicesTag,
    locationvalue,
    HeaderRadius,
    isLoginPage
  } = uiUseStore();
  const { setIsForceLogin, isLoggedIn, setIsLoggedIn, setShowLogin } = uiUseStore();
  const [logo, setLogo] = useState<string | undefined>();
  const [profilePicture, setProfilePicture] = useState<string | undefined>();

  const { tenant, setTenantConfigs, savedProviderList, theme, profileImage } = appStore();


  const navigate = useNavigate();

  useEffect(() => {
    const tenantDetails = UseTenantConfigs(tenant);
    setLogo(tenantDetails.logo.symbol);
  }, []);

  useEffect(() => {
    const tenantDetails = UseTenantConfigs(tenant);
    setLogo(tenantDetails.logo.symbol);
    setTenantConfigs(tenantDetails);
  }, [tenant, theme]);

  useEffect(() => {
    setProfilePicture(profileImage);
  }, [profileImage])

  const [showHeroSearch, setShowHeroSearch] =
    useState<StaySearchFormField | null>();

  useOutsideAlerter(headerInnerRef, (event: MouseEvent) => {
    // Check if the clicked target is inside the element with class name 'pac-container'
    if (
      (event.target as HTMLElement).closest(".pac-container") ||
      (event.target as HTMLElement).closest(".drawer-container") ||
      (event.target as HTMLElement).closest(".dialogContainer")
    ) {
      return; // Do nothing if clicked inside 'pac-container'
    }
    setIsShowCareVeticalLine(true);
    setIsHeaderBorderVisible(true);
    // If clicked outside of headerInnerRef and not inside 'pac-container', setShowHeroSearch to null
    setShowHeroSearch(null);
  });

  useEffect(() => {
    window.addEventListener("scroll", handleEvent);
    return () => {
      window.removeEventListener("scroll", handleEvent);
    };
  }, []);

  useEffect(() => {
    setShowHeroSearch(null);
  }, [savedProviderList]);

  const handleEvent = () => {
    window.requestAnimationFrame(handleHideSearchForm);
  };

  const handleHideSearchForm = () => {
    if (!document.querySelector("#nc-Header-3-anchor")) {
      return;
    }
    //
    let currentScrollPos = window.pageYOffset;
    if (
      WIN_PREV_POSITION - currentScrollPos > 100 ||
      WIN_PREV_POSITION - currentScrollPos < -100
    ) {
      setShowHeroSearch(null);
    } else {
      return;
    }
    WIN_PREV_POSITION = currentScrollPos;
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem(KEYS.ISLOGGEDIN);
    navigate("/");
  };

  //
  const renderHeroSearch = () => {
    return (
      <div
        className={`absolute inset-x-0 top-0 xs:left-5 md:left-0  transition-all will-change-[transform,opacity] ${
          showHeroSearch
            ? "visible"
            : "-translate-x-0 -translate-y-[90px] scale-x-[0.395] scale-y-[0.6] opacity-0 invisible pointer-events-none"
        }`}
      >
        <div className={`w-full max-w-5xl mx-auto pb-6`}>
          <HeroSearchFormSmall />
        </div>
      </div>
    );
  };

  const renderButtonOpenHeroSearch = () => {
    return (
      <div
        className={`w-full relative flex items-center justify-between border border-neutral-200 dark:border-neutral-6000 rounded-full shadow hover:shadow-md transition-all ${
          showHeroSearch
            ? "-translate-x-0 translate-y-20 scale-x-[2.55] scale-y-[1.8] opacity-0 pointer-events-none invisible"
            : "visible"
        }`}
      >
        <div className="flex items-center font-medium xs:text-xs-small md:text-sm">
          <span
            className="block pl-5 pr-4 cursor-pointer xs:py-2 md:py-3 overflow-hidden whitespace-nowrap text-ellipsis"
            onClick={() => setShowHeroSearch("Type of Care")}
          >
            {servicesTag ?? "Type of care"}
          </span>
          <span className="h-5 w-[1px] bg-neutral-300 dark:bg-neutral-700"></span>
          <span
            className="block px-4 cursor-pointer xs:py-2 md:py-3 overflow-hidden whitespace-nowrap text-ellipsis"
            onClick={() => setShowHeroSearch("Zip Code")}
          >
            {locationvalue ?? " Location"}
          </span>
          <span className="h-5 w-[1px] bg-neutral-300 dark:bg-neutral-700"></span>
          <span
            className="block px-4 cursor-pointer xs:py-2 md:py-3 overflow-hidden whitespace-nowrap text-ellipsis"
            onClick={() => setShowHeroSearch("Radius")}
          >
            {HeaderRadius ?? "Distance"}
          </span>
        </div>
        <div
          className="flex-shrink-0 ml-auto pr-2 cursor-pointer"
          onClick={() => setShowHeroSearch("Type of Care")}
        >
          <span className="md:w-8 md:h-8 xs:w-7 xs:h-7 flex items-center justify-center rounded-full bg-primary-6000  text-white">
            <svg
              className="md:w-5 md:h-5 xs:h-4 xs:w-4"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
              ></path>
            </svg>
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`nc-Header nc-Header-3 fixed z-40 top-0 inset-0 bg-black/30 dark:bg-black/50 transition-opacity will-change-[opacity] xs:hidden md:block ${
          showHeroSearch ? "visible" : "invisible opacity-0 pointer-events-none"
        }`}
      ></div>
      {showHeroSearch && <div id="nc-Header-3-anchor"></div>}
      <header ref={headerInnerRef} className={`sticky top-0 z-40 ${className}`}>
        <div
          className={`bg-white dark:bg-neutral-900 absolute h-full inset-x-0 top-0 transition-transform will-change-[transform,opacity]
          ${showHeroSearch ? "duration-75" : ""} ${
            showHeroSearch ? "md:scale-y-[3]" : ""
          }`}
        ></div>
        <div
          className={
            !isHomePage
              ? "relative xs:px-5 md:px-10  py-3 flex"
              : "px-4 lg:container lg:py-2 py-2  lg:px-32 xl:px-56 2xl:px-28 relative flex justify-between items-center"
          }
        >
          <div className="flex-1 flex items-center justify-between">
            {/* Logo (lg+) */}
            <div className={`relative z-10 flex flex-1`}>
              <Logo
                img={logo}
                imgLight={logo}
                className={"transition-opacity duration-700 ease-in w-54"}
              />
            </div>

            {!isHomePage && (
              <div className="xs:flex-0 md:flex-2 lg:flex-none mx-auto xs:hidden md:block">
                <div className="xs:block">{renderButtonOpenHeroSearch()}</div>
                <div className="lg:hidden w-full max-w-lg mx-auto">
                  {/* <HeroSearchForm2MobileFactory /> (replace with actual mobile search component) */}
                </div>
                {renderHeroSearch()}
              </div>
            )}

            {/* NAV */}
            <div className="xs:flex relative z-10 flex-1 items-center justify-end text-neutral-700 dark:text-neutral-100">
              <div className="items-center flex space-x-1">
                <div className="xs:block md:hidden">
                  {!isLoginPage && <SearchTab />}
                </div>
                <ThemeDropdown />
                <SwitchDarkMode />
                {!isHomePage && savedProviderList!.length > 0 && (
                  <SavedProviders className={"xs:hidden "} />
                )}
                {
                  isLoggedIn ?
                    <AvatarDropdown imgUrl={`${profilePicture}`} logout={logout} />
                    : <ButtonOutlined buttonWdith="xs:w-20 md:w-36" onClick={() => { setShowLogin(true) }}>Try now</ButtonOutlined>
                }
                <div
                  className={`${
                    !isHomePage ? "xs:block md:hidden" : ""
                  } md:hidden`}
                >
                  {!isHomePage && <MenuBar />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default StandardHeader;

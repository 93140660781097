import { Tenant } from "types/tenant";

export const getThemeForTenant = (tenant: Tenant) => {
    switch (tenant) {
        case Tenant.DRD:
            return 'drd';
        case Tenant.WELLSTAR:
            return 'wellstar';
        case Tenant.Zocdoc:
            return 'zocdoc';
        case Tenant.Care:
            return 'care';
        case Tenant.Spot:
            return 'spot';
        default:
            return 'theme-cyan-blueGrey';
    }
}
import { Transition } from "@headlessui/react";
import { CarDataType, ExperiencesDataType, StayDataType } from "data/types";
import React, { FC, Fragment } from "react";
import { useState } from "react";
import LocationMarker from "./LocationMarker";
import { MarkerPin } from "types/MarkerPin";
import { ProviderCard } from "containers/PageList/ProviderCard";
import { Providers } from "types/ProviderDetails";

export interface AnyReactComponentProps {
  className?: string;
  isSelected?: boolean;
  lat: number;
  lng: number;
  item: Providers;
  isList?: boolean;
}

const AnyReactComponent: FC<AnyReactComponentProps> = ({
  className = "",
  isSelected,
  item,
  isList = true
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`nc-AnyReactComponent relative  ${className}`}
      data-nc-id="AnyReactComponent"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >

      <div className={`text-primary-500 bg-white`}>
        <div className={`${isSelected ? "text-white bg-secondary-500 shadow-lg" : "bg-primary-500 text-white shadow-md"} h-6 w-10 text-center p-1  rounded-md text-xs`}>
          ⭐ {item.rating?.overall}
        </div>
      </div>
      {
        isList &&
        <Transition
          show={isSelected || isOpen}
          as={Fragment}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute z-50 bottom-full pb-3 -left-12 w-[400px] aspect-w-1">

            <ProviderCard data={item} isLite />
          </div>
        </Transition>
      }

    </div>
  );
};

export default AnyReactComponent;

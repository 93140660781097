import { Cares, CareTypes } from "types/CareTypes";
import { mapListToType, mapToBaseResponse } from "utils/mapper";
import { Endpoint } from "./endpoint";
import { EndpointConstants } from "constants/EndpointConstants";
import { Filters } from "types/filterProps";
import { ProviderDetails, Providers, Section } from "types/ProviderDetails";
import handleError from "utils/handleError";
import { LoginResponse } from "types/responseTypes/loginResponse";
import { ContactTypes } from "types/ContactTypes";

export const Services = {
    LoadCareTypes: async () => {
        let result = await Endpoint.get(EndpointConstants.MsAllCategories);
        return mapListToType<Cares>(result["data"] ?? []).sort((a, b) => a.name.localeCompare(b.name));
    },
    LoadCaresAgainstFilters: async (filter: Filters) => {

        let result = await Endpoint.get(EndpointConstants.MsProvidersDetailV2, {
            radius: filter.radius.split(" ")[0],
            lat: filter.lat,
            lon: filter.lon,
            careType: filter.careType,
            page: filter.page,
            pageSize: 1000

        });
        let providersList = mapListToType<Providers>(result["data"] ?? []);
        let uniqueProviders = new Set();
        let filteredProviders = [];
        for (let provider of providersList) {
            if (!uniqueProviders.has(provider.name)) {
                uniqueProviders.add(provider.name);
                filteredProviders.push(provider);
            }
        }

        return { data: filteredProviders, total: result["total"] };
    },
    LoadQnA: async (providerId: string) => {
        let result = await Endpoint.get(EndpointConstants.MsProviderWithQA + providerId);
        return mapListToType<Section>(result["data"] ?? []);
    },
    SendEmail: async (blob: Blob, emailId: string) => {
        const formData = new FormData();
        formData.append("file", blob, "providers.pdf");
        formData.append("email", emailId);
        try {
            let result = await Endpoint.postFormData(EndpointConstants.SendEmail, formData);
            return result;
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    },
    SaveReport: async (code: string, category: string, desc: string) => {
        try {
            let result = await Endpoint.post(EndpointConstants.SaveReport, {
                "code": code,
                "category": category,
                "desc": desc
            }, true);
            return result;
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    },
    LoadCaresForHomeScreen: async () => {

        let result = await Endpoint.get(EndpointConstants.MsProvidersDetailV2, {
            radius: 20,
            lat: 40.7127753,
            lon: -74.0059728,
            careType: 'Skilled Nursing',
            page: 1,
            pageSize: 1000

        });
        let providersList = mapListToType<Providers>(result["data"] ?? []);
        let uniqueProviders = new Set();
        let filteredProviders = [];
        for (let provider of providersList) {
            if (!uniqueProviders.has(provider.name)) {
                uniqueProviders.add(provider.name);
                filteredProviders.push(provider);
            }
        }

        return { data: filteredProviders, total: result["total"] };
    },
    Login: async (email: string, password: string) => {
        try {
            const result = await Endpoint.post(EndpointConstants.Login, { email, password }, true);
            if (result.status === "success") {
                let data = mapToBaseResponse<LoginResponse>(result).data;
                if (data) {
                    return data;
                }
            }
            throw new Error("Login failed.");
        } catch (error) {
            handleError(error, "Login");
        }
    },
    Register: async (firstName: string, lastName: string, phone: string, email: string, password: string) => {
        try {
            const result = await Endpoint.post(EndpointConstants.Register, { firstName, lastName, phone, email, password }, true);
            if (result.status === "success") {
                return result.data;
            }
            throw new Error("Registration failed.");
        } catch (error) {
            handleError(error, "Register");
        }
    },
    updateProfile: async (blob: Blob | null, firstName: string, lastName: string, phone: string, email: string) => {
        const formData = new FormData();
        if(blob instanceof Blob) {
            formData.append("file", blob, "profile.png");
        }
        
        formData.append("email", email);
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("phone", phone);
        try {
            let result = await Endpoint.postFormData(EndpointConstants.UpdateProfile, formData);
            let data = mapToBaseResponse<LoginResponse>(result).data;
            if (data) {
                return data;
            }
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    },
    Update: async (firstName: string, lastName: string, phone: string, email: string) => {
        try {
            const result = await Endpoint.post(EndpointConstants.Update, { firstName, lastName, phone, email }, true);
            if (result.status === "success") {
                return true;
            }
            throw new Error("update failed.");
        } catch (error) {
            handleError(error, "Register");
        }
    },
    Verify: async (otp: string, email: string) => {
        try {
            const result = await Endpoint.post(EndpointConstants.Verify, { otp, email }, true);
            if (result.status === "success") {
                return result.data;
            }
            throw new Error("OTP verification failed.");
        } catch (error) {
            handleError(error, "Verify");
        }
    },
    RetryVerification: async (email: string) => {
        try {
            const result = await Endpoint.post(EndpointConstants.RetryVerification, { email }, true);
            if (result.status === "success") {
                return result.data;
            }
            throw new Error("OTP verification failed.");
        } catch (error) {
            handleError(error, "RetryVerification");
        }
    },
    GetUserByEmail: async (email: string) => {
        try {
            const result = await Endpoint.get(EndpointConstants.GetUserByEmail + `?email=${email}`);
            let data = mapToBaseResponse<LoginResponse>(result).data;
            if (data) {
                return data;
            }
            throw new Error("Profile retriving failed.");
        } catch (error) {
            handleError(error, "GetUserByEmail");
        }
    },
    //Create Contact Service
    CreateContact: async (contactMessage: ContactTypes) => {
        try {
            const result = await Endpoint.post(EndpointConstants.CreateContactMessage, {
                "fullName": contactMessage.fullName,
                "email": contactMessage.email,
                "message": contactMessage.message
            }, true)
            return result
        } catch (error) {
            handleError(error, "CreateContact")
        }
    }
}
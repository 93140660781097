import { Dialog, Transition } from "@headlessui/react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import CustomDialog from "components/Dialog/CustomDialog";
import ForceLogin from "components/ForceLogin/ForceLogin";
import Heading2 from "components/Heading/Heading2";
import SectionHero from "components/SectionHero/SectionHero";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { APP_CONSTANTS } from "constants/AppConstants";
import { KEYS } from "constants/KeyConstants";
import PageList from "containers/PageList/PageList";
import ListingStayMapPage from "containers/PageList/ProviderListV2";
import { parseProviderResults } from "contains/makers";
import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Services } from "services/service";
import Footer from "shared/Footer/Footer";
import Pagination from "shared/Pagination/Pagination";
import appStore from "store/AppStore";
import uiUseStore from "store/UIStore";
import useCookie from "utils/useCookies";

function PageHome() {
  const {
    setCareTypes,
    setLoader,
    loading,
    setProvidersList,
    setFilteredPaginatedList,
    setOriginalList,
    setPaginationDetails,
    setSavedProviderList
  } = appStore();
  const { setIsHomePage, isForceLogin, setIsForceLogin } = uiUseStore();

  const { getCookie } = useCookie();
  const navigate = useNavigate();

  useEffect(() => {
    if (getCookie(KEYS.VERIFICATIONPENDING) == "true") {
      navigate("/verify");
    }
    setIsHomePage(true);
    LoadHomeScreenList();
    LoadAllCares();
  }, []);

  const LoadAllCares = async () => {
    let result = await Services.LoadCareTypes();
    setCareTypes(result);
  };

  const LoadHomeScreenList = async () => {
    let { data, total } = await Services.LoadCaresForHomeScreen();
    const filterData = {
      radius: "20",
      lat: 40.7127753,
      lon: -74.0059728,
      careType: "Skilled Nursing",
      page: 1,
      pageSize: 1000,
      postalCode: "0"
    };
    setPaginationDetails({
      total: total,
      currentPage: 1,
      totalPages: Math.ceil(total / 10)
    });
    let result = await parseProviderResults(data, filterData);
    setProvidersList(result);
    setOriginalList(result);
    setFilteredPaginatedList(result.slice(0, APP_CONSTANTS.PAGINATION_LIMIT));
  };

  return (
    <div
      className={`nc-ListingStayMapPage relative`}
      data-nc-id="ListingStayMapPage"
    >
      <BgGlassmorphism />
      <div className="relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <SectionHero className="xs:p-5 sm:p-8 md:px-28 md:mt-4 lg:container lg:mb-72 lg:mt-8" />
      </div>
      <div className="pt-1 pb-20 lg:pb-8 2xl:pl-10 xl:pr-0 xl:max-w-none">
        <ListingStayMapPage />
      </div>
      {/* <div className="w-full xs:p-5 sm:p-8 lg:pl-20 lg:pr-20 relative border-t border-gray-200 dark:border-gray-500">
        
      </div> */}
      <Footer />
    </div>
  );
}

export default PageHome;

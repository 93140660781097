import { HeartIcon } from "@heroicons/react/24/outline";
import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import NavMobile from "shared/Navigation/NavMobile";
import { Transition, Dialog } from "@headlessui/react";
import uiUseStore from "store/UIStore";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CustomDialog from "components/Dialog/CustomDialog";
import appStore from "store/AppStore";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import SuccessDialog from "shared/SuccessDialog/SuccessDialog";
import { BlobProvider } from "@react-pdf/renderer";
import ReportTemplate from "shared/PDFTemplate/ReportTemplate";
import { Providers } from "types/ProviderDetails";
import { Services } from "services/service";
import Loader from "shared/Loader/Loader";

export interface SavedProvidersProps {
  className?: string;
}

const SavedProviders: FC<SavedProvidersProps> = ({ className = "" }) => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const emailSendRef = useRef<HTMLFormElement>(null);
  const {
    EmailDialogOpen,
    setEmailDialogOpen,
    setDrawerClose,
    isDrawerClose,
    CareType,
    isEmailSendClicked,
    setIsEmailSendClicked
  } = uiUseStore((state) => ({
    EmailDialogOpen: state.EmailDialogOpen,
    setEmailDialogOpen: state.setEmailDialogOpen,
    setDrawerClose: state.setIsDrawerClose,
    isDrawerClose: state.isDrawerClose,
    CareType: state.CareType,
    isEmailSendClicked: state.isEmailSendClicked,
    setIsEmailSendClicked: state.setIsEmailSendClicked
  }));
  const { savedProviderList, tenantConfig, loading, setLoader } = appStore();
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [saveProfileDetails, setSaveProfileDetails] = useState<Providers[]>([]);

  useEffect(() => {
    if (isEmailSendClicked) {
      setSaveProfileDetails(savedProviderList || []);
    }
  }, [isEmailSendClicked, savedProviderList]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && !isEmailSendClicked) {
        event.preventDefault(); // Prevent default form submission
        emailSendRef.current?.requestSubmit(); // Trigger form submission programmatically
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isEmailSendClicked]);

  const handleOpenMenu = () => setDrawerClose(false);

  const handleCloseMenu = () => {
    debugger
    setEmailDialogOpen(false);
  };

  const handleClose = () => {
    debugger
    console.log("close");
    setEmailDialogOpen(false);
  };

  const handleSuccessDilaogClose = () => {
    setIsSuccessDialogOpen(false);
  };

  const onEmailSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    debugger
  
    e.preventDefault();
    if (emailInputRef.current?.checkValidity()) {
      setIsEmailSendClicked(true);
      setEmailDialogOpen(false);
      setEmail(emailInputRef.current.value);
      setIsSuccessDialogOpen(true);
    }
  };

  const renderContent = () => {
    return (
      <Transition appear show={!isDrawerClose} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 overflow-hidden"
          onClose={handleCloseMenu}
        >
          <Transition.Child
            as={Fragment}
            enter=" duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave=" duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50" />
          </Transition.Child>
          <div className="fixed inset-0">
            <div className="flex justify-end min-h-full ">
              <Transition.Child
                as={Fragment}
                enter="transition duration-100 transform"
                enterFrom="opacity-0 translate-x-56"
                enterTo="opacity-100 translate-x-0"
                leave="transition duration-150 transform"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-56"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden transition-all ">
                  <NavMobile />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const EmailDialogPanel = () => {

    
    const sendEmail = async (blob: Blob) => {

      await Services.SendEmail(blob, email);
      setLoader(false);
      setIsEmailSendClicked(false);
      setEmailDialogOpen(false);
      setIsSuccessDialogOpen(true);
    };

    return (
      <>
        <div className="flex flex-row text-neutral-700 dark:text-neutral-300 text-center justify-between items-center border-b dark:border-neutral-800 py-3 px-2 relative">
          <p className="text-xl font-semibold flex-grow">
            Email Saved Providers
          </p>
          <span className="flex-shrink-0">
            <ButtonClose onClick={handleClose} />
          </span>
        </div>
        <div className="flex flex-col text-neutral-700 dark:text-neutral-300 justify-center items-center gap-y-6 px-6 pt-6 pb-5">
          <p className="text-base font-normal text-center">
            You can send the saved providers to recipients. Please enter the email address
          </p>
          <form
            className="w-full flex flex-col justify-center items-center gap-y-6"
            ref={emailSendRef}
            onSubmit={onEmailSubmit}
          >
            <div className="w-full">
              <span className="font-semibold text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                required
                aria-required="true"
                placeholder="example@gmail.com"
                className={`mt-2`}
                rounded="rounded-lg "
                ref={emailInputRef}
                onInvalid={(e) => {
                  const target = e.target as HTMLInputElement;
                  if (!target.value) {
                    target.setCustomValidity(
                      "Please fill out the email address"
                    );
                  } else {
                    target.setCustomValidity(
                      "Please include a valid email address"
                    );
                  }
                }}
                onInput={(e) => {
                  (e.target as HTMLInputElement).setCustomValidity("");
                }}
              />
            </div>
            <div className="w-full flex flex-row justify-between items-center">
              <ButtonSecondary onClick={handleClose} className="rounded">
                Cancel
              </ButtonSecondary>

              {isEmailSendClicked ? (
                <BlobProvider
                  document={
                    <ReportTemplate
                      currentDateTime={new Date()
                        .toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit"
                        })
                        .replace(",", "")}
                      savedProviderDetails={saveProfileDetails}
                      logo={tenantConfig.logo.full}
                      caretype={CareType ?? ""}
                    />
                  }
                >
                  {({ blob, url, loading, error }) => {
                    if (loading) return "sending...";
                    if (error) return "Error loading document";
                    if (blob) {
                      if (isEmailSendClicked) {
                        sendEmail(blob);
                      }
                    }
                    return null;
                  }}
                </BlobProvider>
              ) : (
                <ButtonPrimary type="submit" className="rounded p-1">
                  Send
                </ButtonPrimary>
              )}
            </div>
          </form>
        </div>
        {/* <Loader isVisible={loading} /> */}
      </>
    );
  };

  return (
    <>
      <button
        onClick={handleOpenMenu}
        className={`text-2xl md:text-3xl relative w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none md:flex items-center justify-center ${className} `}
      >
        <HeartIcon className="w-7 h-7" aria-hidden="true" />
        <span
          className={`${
            savedProviderList && savedProviderList?.length > 0
              ? "absolute bg-red-500 p-1 rounded-full top-2 right-1 xs:top-0 xs:right-0 xs:p-0"
              : ""
          }`}
        ></span>
      </button>
      {renderContent()}
      {/* <CustomDialog
        isDialogOpen={EmailDialogOpen}
        handleClose={handleClose}
        children={<EmailDialogPanel />}
        DialogTitle="Email Saved Providers"
      />
      <CustomDialog
        isDialogOpen={isSuccessDialogOpen}
        handleClose={handleSuccessDilaogClose}
        children={
          <SuccessDialog
            img={`${process.env.PUBLIC_URL}/success.png`}
            content="Saved providers has been successfully emailed to "
            contentToBold={email}
            btnString="Close"
            onClick={() => {
              setIsSuccessDialogOpen(false);
            }}
          />
        }
      /> */}
    </>
  );
};

export default SavedProviders;

import React, { useEffect } from "react";
import PACRNRoutes from "routers/index";
import Loader from "shared/Loader/Loader";
import appStore from "store/AppStore";
import { getThemeForTenant } from "utils/theme";
import { Toaster } from "react-hot-toast";

function App() {
  const { loading, tenant } = appStore();

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = getThemeForTenant(tenant);
    }
    return () => {
      if ($body) {
        $body.className = "theme-cyan-blueGrey";
      }
    };
  }, [tenant]);

  return (
    <>
      {/* {loading && (
        <div className="fixed inset-0 bg-gray-300 bg-opacity-50 z-50 flex justify-center items-center pointer-events-none">
          <Loader isVisible={loading} />
        </div>
      )} */}
      <div className=" text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 ">
        <PACRNRoutes />
        <Toaster containerStyle={{ zIndex: 9999999999 }} />
      </div>
    </>
  );
}

export default App;

import React from "react";
import { ReactNode } from "react";

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
  headingStyle?: string;
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
  heading = "233 Providers",
  subHeading,
  headingStyle = ""
}) => {
  return (
    <div className={`mb-2 mt-2 lg:mb-2 lg:mt-2 ${className}`}>
      <h2 className={headingStyle ? `${headingStyle}` :`text-xl xs:text-lg font-semibold`}>{heading}</h2>
      {subHeading &&
        subHeading
      }
    </div>
  );
};

export default Heading2;

import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Label from "shared/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import PageContactSubscribeSection from "./PageContactSubscribeSection";
import React, { useRef } from "react";
import { ContactTypes } from "types/ContactTypes";
import { Services } from "services/service";
import toast from "react-hot-toast";
import appStore from "store/AppStore";

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter"
  },
  {
    title: "💌 EMAIL",
    desc: "Support@spot.care"
  },
  {
    title: "☎ PHONE",
    desc: "000-123-456-7890"
  }
];

const PageContact = () => {
  const fullNameInputRef = useRef<HTMLInputElement>(null);
  const EmailInputRef = useRef<HTMLInputElement>(null);
  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const { loading, setLoader } = appStore();

  const handleSendMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    let ContactMessage: ContactTypes = {
      fullName: fullNameInputRef.current?.value ?? "",
      email: EmailInputRef.current?.value ?? "",
      message: messageInputRef.current?.value ?? ""
    };
    let result = await Services.CreateContact(ContactMessage);

    try {
      if (result.status.toLowerCase() === "success" || result.data.id > 0) {
        setLoader(false);
        toast.success(result.message, { position: "top-right" });
      } else {
        toast.error("Something Went Wrong, Try Again", {
          position: "top-right"
        });
      }
    } catch (ex) {
      setLoader(false);
      toast.error("Something Went Wrong, Try Again", { position: "top-right" });
    }
  };

  return (
    <div className="w-full flex flex-col justify-start items-center">
      {/*Contact Tab*/}
      <div className="md:w-7/12 xs:w-full md:my-24 xs:my-12 py-2 flex flex-col justify-start gap-16">
        <h2 className="text-center md:text-5xl xs:text-4xl font-semibold text-neutral-900 dark:text-neutral-100">
          Contact
        </h2>
        {/**Contact Fiels and its info */}
        <div className="w-full flex md:flex-row xs:flex-col justify-between items-start md:gap-0 xs:gap-8">
          {/**Contact Info */}
          <div className="md:w-1/2 xs:w-full flex flex-col justify-start items-start gap-12 px-4">
            {info.map((item, index) => (
              <div key={index}>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  {item.title}
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400 text-wrap">
                  {item.desc}
                </span>
              </div>
            ))}
          </div>
          {/**Contact Fields */}
          <form
            className="md:w-1/2 xs:w-full flex flex-col justify-start items-start gap-8 px-4"
            onSubmit={handleSendMessage}
          >
            <label className="block w-full">
              <Label>Full name</Label>
              <Input
                required
                aria-required="true"
                placeholder="Your Name"
                type="text"
                className="mt-1"
                ref={fullNameInputRef}
              />
            </label>
            <label className="block w-full">
              <Label>Email address</Label>
              <Input
                required
                aria-required="true"
                placeholder="email@gmail.com"
                type="email"
                className="mt-1"
                ref={EmailInputRef}
                onInvalid={(e) => {
                  const target = e.target as HTMLInputElement;
                  if (!target.value) {
                    target.setCustomValidity(
                      "Please fill out the email address"
                    );
                  } else {
                    target.setCustomValidity(
                      "Please include a valid email address"
                    );
                  }
                }}
                onInput={(e) => {
                  (e.target as HTMLInputElement).setCustomValidity("");
                }}
              />
            </label>
            <label className="block w-full">
              <Label>Message</Label>
              <Textarea
                required
                aria-required="true"
                className="mt-1"
                rows={6}
                ref={messageInputRef}
              />
            </label>
            <ButtonPrimary
              loading={loading}
              type="submit"
              className="rounded p-1"
            >
              Send Message
            </ButtonPrimary>
          </form>
        </div>
      </div>
      {/**Subscribe Section*/}
      <PageContactSubscribeSection />
    </div>
  );
};

export default PageContact;

import React, { FC, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import uiUseStore from "store/UIStore";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Services } from "services/service";
import appStore from "store/AppStore";
import toast from "react-hot-toast";
import { KEYS } from "constants/KeyConstants";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import care1 from "images/banners/care1.png";
import care from "images/banners/care3.png";
import logo from "images/app/spot/symbol.png";

export interface PageLoginProps {
  className?: string;
}

interface FormData {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required")
});

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const { setIsLoginPage, setIsForceLogin, setIsLoggedIn, setShowLogin, setShowSignup } = uiUseStore();
  const { loading, setLoader, setUserDetail, setProfileImage } = appStore();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Array of image URLs for the carousel
  const carouselImages = [
    care1,
    care,
    "https://via.placeholder.com/400x300?text=Image+3",
  ];

  // useEffect(() => {
  //   setIsLoginPage(true);
  //   if (NorecordContainerRef.current) {
  //     const lottieInstance = lottie.loadAnimation({
  //       container: NorecordContainerRef.current,
  //       renderer: "svg",
  //       loop: true,
  //       autoplay: true,
  //       animationData: login
  //     });

  //     return () => {
  //       lottieInstance.destroy();
  //     };
  //   }
  // }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
    }, 3000);
    return () => clearInterval(interval); // Cleanup on component unmount
  }, [carouselImages.length]);


  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setLoader(true);
      let result = await Services.Login(data.email, data.password);
      if (result) {
        localStorage.setItem(KEYS.ISLOGGEDIN, "true");
        localStorage.setItem(KEYS.TOKEN, result?.token!.toString());
        localStorage.setItem(KEYS.EMAIL, data.email);
        localStorage.setItem(KEYS.PROFILEIMAGE, result?.profilePicture!);

        setProfileImage(result?.profilePicture!);

        setShowLogin(false);
        setIsLoggedIn(true);
        setUserDetail(result);
        toast.success("Signed in Successfully");
      }
    } catch (error) {
      toast.error("Error in Login");
    } finally {
      setLoader(false);
    }
  };

  // return (
  //   <div className={`nc-PageLogin w-full rounded-xl flex  ${className}`} data-nc-id="PageLogin">
  //     <div className="hidden lg:block xl:block flex-1 p-4" ref={NorecordContainerRef}>
  //     </div>
  //     <div className="flex-1 justify-center items-center w-full space-y-6 xs:mx-10 mx-20">
  //       <h2 className="text-3xl  font-semibold text-neutral-900 dark:text-neutral-100 text-center">Login</h2>
  //       <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
  //         <div className="text-left">
  //           <label className="text-neutral-800 dark:text-neutral-200 text-xs ml-4">Email</label>
  //           <input
  //             {...register("email")}
  //             type="email"
  //             placeholder="example@example.com"
  //             className={`mt-1 block w-full h-11 px-4 py-2 text-sm rounded-2xl border ${errors.email ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
  //           />
  //           {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
  //         </div>
  //         <div className="text-left">
  //           <label className="text-neutral-800 dark:text-neutral-200 text-xs ml-4">Password</label>
  //           <input
  //             {...register("password")}
  //             type="password"
  //             placeholder="Password"
  //             className={`mt-1 block w-full h-11 px-4 py-2 text-sm rounded-2xl border ${errors.password ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
  //           />
  //           {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password.message}</p>}
  //         </div>
  //         <ButtonPrimary loading={loading} className="w-full" type="submit">Login</ButtonPrimary>

  //       </form>
  //     </div>
  //   </div>
  // );

  const onCreateAccountClick = () => {
    setShowLogin(false);
    setShowSignup(true);

  }

  return (
    <div className={`nc-PageLogin w-full rounded-xl flex ${className}`}>
      <div className="max-w-4xl p-2 w-full rounded-lg shadow-lg flex flex-col md:flex-row">
        {/* Left Side */}
        <div className="hidden md:flex md:w-1/2 bg-gray-100 rounded-l-lg flex-col justify-center">

          <div className="relative h-full w-full rounded-lg overflow-hidden">
            <img src={care} className="object-cover h-full w-full" alt="Healthcare Image" />
            <div className="absolute inset-0 bg-black bg-opacity-20"></div>
          </div>

        </div>

        {/* Right Side */}
        <div className="w-full my-20 xs:my-5 md:w-1/2 p-8 text-center align-middle justify-center">
          <div className="justify-center mb-8">
            <h3 className="text-[1.75rem] font-medium text-left py-[11px]">Welcome back 👋</h3>
            <h4 className="font-normal text-left text-[0.9rem] text-gray-500">Please sign in to continue</h4>
          </div>
          {/* <h3 className="text-2xl font-bold mb-4">Get started</h3> */}
          <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-4 mt-5">
            <div className="text-left">
              <label className="text-neutral-800 dark:text-neutral-200 text-[0.85rem] ml-1">Email</label>
              <input
                {...register("email")}
                type="email"
                placeholder="Enter your email address"
                className={`mt-1 block w-full h-11 px-4 py-2 text-sm rounded-md border ${errors.email ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
              />
              {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
            </div>
            <div className="text-left">
              <label className="text-neutral-800 dark:text-neutral-200 text-[0.85rem] ml-1">Password</label>
              <input
                {...register("password")}
                type="password"
                placeholder="Enter the password"
                className={`mt-1 block w-full h-11 px-4 py-2 text-sm rounded-md border ${errors.password ? 'border-red-500' : 'border-gray-300'} focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
              />
              {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password.message}</p>}
            </div>
            <div className="text-right text-[0.85rem] text-gray-500 hover:underline cursor-pointer">Forgot Password?</div>
            <ButtonPrimary translate="rounded-md" loading={loading} className="w-full" type="submit">
              Login
            </ButtonPrimary>
          </form>
          <div className="text-[0.85rem] text-gray-500 text-center mt-6">
            Don't have account? <span className="text-primary-500 hover:underline cursor-pointer" onClick={onCreateAccountClick}>Sign up</span>
          </div>
        </div>
      </div>
      <span className="absolute right-3 top-3">
        <ButtonClose
          sizes='w-6 h-6'
          className='cursor-pointer'
          onClick={() => {
            setShowLogin(false);
          }}
        />
      </span>
    </div>

  );

};

export default PageLogin;
